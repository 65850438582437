'use client';

import { Variation } from '@/components/ui/Badge';
import Chart from '@/components/ui/Chart';
import Icon from '@/components/ui/Icon';
import Spinner from '@/components/ui/Spinner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
const DashboardCard = ({
  label,
  value,
  loading,
  text,
  icon,
  chartData,
  color,
  variation,
  variationInvert,
  href,
  tooltip,
  children,
  progress,
  className,
  ...props
}) => {
  const t = useTranslations();
  const classes = cn({
    card: true,
    [`card-${color}`]: color,
    'dashboard-card': true,
    [className]: className
  });
  const contentClasses = cn({
    'card-content': true,
    'w-full': !chartData,
    [className]: className
  });
  const CardWrapper = ({
    children
  }) => {
    if (href) {
      return <Link className={classes} href={href}>
          {children}
        </Link>;
    }
    return <div className={classes} data-sentry-component="CardWrapper" data-sentry-source-file="DashboardCard.jsx">{children}</div>;
  };
  const CardNotes = ({
    children
  }) => {
    return <span className="block mt-1 text-sm font-light leading-5 opacity-60" data-sentry-component="CardNotes" data-sentry-source-file="DashboardCard.jsx">
        {children}
      </span>;
  };
  return <CardWrapper className={classes} {...props} data-sentry-element="CardWrapper" data-sentry-component="DashboardCard" data-sentry-source-file="DashboardCard.jsx">
      {tooltip && <div className="card-tooltip">
          <Tooltip>
            <TooltipTrigger>
              <Icon name="Info" />
            </TooltipTrigger>
            <TooltipContent className="max-w-[280px]">
              <span dangerouslySetInnerHTML={{
            __html: tooltip
          }} />
            </TooltipContent>
          </Tooltip>
        </div>}

      <div className={contentClasses}>
        <h4>
          {icon && <div className="card-icon">
              <Icon name={icon} />
            </div>}
          {label}
        </h4>

        {loading && <div className="card-loading">
            <Spinner inline />
          </div>}

        {!loading && <div className="card-value">
            {value}

            {variation !== undefined && <Variation invert={variationInvert} className="ml-2" value={variation} />}
          </div>}

        {!value && !loading && <CardNotes>
            <Icon name="TriangleAlert" className="mr-1" />
            {t('empty_state.dashboard.missing_data')}
          </CardNotes>}

        {text && <CardNotes>{text}</CardNotes>}

        {(variation > 0 || variation < 0) && !text && !loading && <CardNotes>
            {t('common.time.periods.month_period')}
          </CardNotes>}

        {progress && !loading && progress}
      </div>

      {chartData && !loading && <div className="card-chart">
          <Chart data={chartData} color={color} id={label} showArea={true} showBottomAxis={false} showLeftAxis={false} />
        </div>}

      {children}
    </CardWrapper>;
};
export default DashboardCard;