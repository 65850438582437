import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { ROUTES_DATA } from '@/data';
import { useLocale, useTranslations } from '@/hooks';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
export const NotificationItem = ({
  notification
}) => {
  const {
    content,
    type
  } = notification;
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  dayjs.locale(locale);
  const getIcon = () => {
    switch (type) {
      case 'accelerate':
        return ROUTES_DATA.ACCELERATE.icon;
      case 'analysis':
        return ROUTES_DATA.ANALYSIS.icon;
      case 'limit':
        return 'TriangleAlert';
      case 'submission':
        return ROUTES_DATA.SUBMISSIONS.icon;
      case 'submission_accept':
        return 'Check';
      case 'plan':
        return ROUTES_DATA.PRICING.icon;
      case 'push':
        return ROUTES_DATA.NOTIFICATIONS.icon;
      case 'vip':
        return ROUTES_DATA.VIP.icon;
      default:
        return ROUTES_DATA.NOTIFICATIONS.icon;
    }
  };
  const getTime = () => {
    return dayjs(notification.createdAt).locale(locale).fromNow();
  };
  const NotificationCta = () => {
    if (notification.metadata && notification.metadata.cta) {
      return <div className="notification-item-cta">
          <Button small icon="Eye" variant="outline-primary" label={t(notification.metadata.cta.label)} className="notification-item-cta" href={notification.metadata.cta.url} />
        </div>;
    }
  };
  return <div className="notification-item" data-sentry-component="NotificationItem" data-sentry-source-file="NotificationItem.jsx">
      <div className="notification-item-inner">
        <div className="notification-item-icon">
          <Icon name={getIcon()} data-sentry-element="Icon" data-sentry-source-file="NotificationItem.jsx" />
        </div>
        <div className="notification-item-content">
          <div dangerouslySetInnerHTML={{
          __html: content
        }} />
          <div className="mt-1 notification-item-time">{getTime()}</div>
        </div>
      </div>
      <NotificationCta data-sentry-element="NotificationCta" data-sentry-source-file="NotificationItem.jsx" />
    </div>;
};
export default NotificationItem;