'use client';

import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Empty from '@/components/ui/Empty';
import Icon from '@/components/ui/Icon';
import Spinner from '@/components/ui/Spinner';
import { useScrollDirection, useLocale, useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { useChatStore } from '@/store';
import { cn, getDateFormat } from '@/utils';
const MessageAttachments = ({
  message
}) => {
  if (!message?.attributes || !Object.keys(message.attributes).length > 0) {
    return null;
  }
  return <div className="message-attachments" data-sentry-component="MessageAttachments" data-sentry-source-file="ChatMessages.jsx">
      {Object.keys(message.attributes).map(key => {
      const attachment = message.attributes[key];
      if (attachment && typeof attachment === 'object') {
        return <MessageAttachment key={key} url={attachment.url} title={attachment.name} type={attachment.type} icon={attachment.icon} image={attachment.image} />;
      }
    })}
    </div>;
};
const MessageAttachment = ({
  image,
  type,
  icon,
  title,
  url
}) => {
  return <Link href={url} target="_blank" className="message-attachment" data-sentry-element="Link" data-sentry-component="MessageAttachment" data-sentry-source-file="ChatMessages.jsx">
      <div className="message-attachment-header">
        <span className="flex items-center gap-1">
          {icon && <Icon name={icon} />}
          {type}
        </span>
      </div>
      <div className="message-attachment-body">
        <div className="message-attachment-image">
          <img src={image} alt={title} />
        </div>
        <span className="text-sm font-medium">{title}</span>
      </div>
    </Link>;
};
export const ChatMessages = ({
  isLoading,
  messages,
  onLoadMore,
  total,
  user
}) => {
  const chatRef = useRef(null);
  const chatEndRef = useRef(null);
  const {
    scrollDirection,
    setScrollDirection
  } = useScrollDirection(chatRef, 50);
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const {
    activeSid,
    loadedChats
  } = useChatStore();
  const {
    ref: loadMoreRef,
    inView
  } = useInView({
    threshold: 1,
    delay: 1000
  });
  useEffect(() => {
    if (loadedChats.includes(activeSid)) {
      const blockEnd = document.querySelectorAll('.block-end');
      if (blockEnd?.length > 0) {
        chatRef.current.scrollTo({
          top: blockEnd[0]?.offsetTop - blockEnd[0]?.offsetHeight,
          behavior: 'instant'
        });
      }
    }
    if (!loadedChats.includes(activeSid) && chatEndRef.current?.offsetTop) {
      chatRef.current.scrollTo({
        top: chatEndRef.current.offsetTop,
        behavior: 'instant'
      });
    }
  }, [activeSid, messages]);
  useEffect(() => {
    if (inView && !isLoading && scrollDirection === 'up') {
      onLoadMore();
      setScrollDirection('idle');
    }
  }, [inView]);
  return <div ref={chatRef} className="chat-body" data-sentry-component="ChatMessages" data-sentry-source-file="ChatMessages.jsx">
      {messages && total > messages.length && !isLoading && <div ref={loadMoreRef} className="w-full h-1 pb-8"></div>}

      {isLoading && <div className="flex items-center justify-center w-full pt-4 pb-8">
          <Spinner inline />
        </div>}

      {messages && messages?.length > 0 && messages.map((msg, index) => {
      const isSelf = msg.author === user.spotifyId;
      const isLastOfGroup = messages.indexOf(msg) === messages.length - 1 || messages[messages.indexOf(msg) + 1].author !== msg.author;
      return <div key={index} className={cn({
        message: true,
        self: isSelf,
        last: isLastOfGroup,
        'block-start': msg?.blockStart,
        'block-end': msg?.blockEnd
      })}>
              {msg.body}
              {msg.dateCreated && <small>
                  {dayjs(msg.dateCreated).format(getDateFormat(locale, true))}
                </small>}

              <MessageAttachments message={msg} />
            </div>;
    })}

      {!isLoading && messages && messages?.length === 0 && <Empty small ghost className="h-full m-0" message={t('empty_state.chat.no_messages')} icon="MessagesSquare" />}

      {!isLoading && <div ref={chatEndRef} className="w-full h-1"></div>}
    </div>;
};
export default ChatMessages;