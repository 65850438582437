import Button from '@/components/ui/Button';
import Empty from '@/components/ui/Empty';
import { ROUTES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const AuthVerify = ({
  children,
  className,
  wrapperClasses
}) => {
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  if (!session) {
    return <div className={cn(wrapperClasses)}>
        <Empty login icon="MailOpen" className={className ? className : ''} message={t('navigation.login.continue')} />
      </div>;
  }
  if (!user.verified) {
    return <div className={cn(wrapperClasses)}>
        <Empty className={className ? className : ''} icon="MailOpen" message={t('email_verification.confirm.lock')}>
          <Button className="mt-4" href={ROUTES.PROFILE}>
            {t('email_verification.confirm.actions.confirm')}
          </Button>
        </Empty>
      </div>;
  }
  return <>{children}</>;
};
export default AuthVerify;