import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { PLANS } from '@/data';
import { useTranslations } from '@/hooks';
import { planRedirect } from '@/utils';
export const ChatRestrictions = ({
  open,
  setOpen
}) => {
  const t = useTranslations();
  const descriptionText = t('paywall.chat.description', {
    agency: <Button key={PLANS.AGENCY} variant="link" href={planRedirect(PLANS.AGENCY)}>
        Agency
      </Button>,
    artist: <Button key={PLANS.ARTIST} variant="link" href={planRedirect(PLANS.ARTIST)}>
        Artist
      </Button>,
    shouldParse: true
  });
  return <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-component="ChatRestrictions" data-sentry-source-file="ChatRestrictions.jsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="ChatRestrictions.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="ChatRestrictions.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="ChatRestrictions.jsx">{t('paywall.chat.title')}</DialogTitle>
        </DialogHeader>

        <p className="mb-6 text-xl">{descriptionText}</p>
        <Alert data-sentry-element="Alert" data-sentry-source-file="ChatRestrictions.jsx">{t('paywall.chat.note')}</Alert>
      </DialogContent>
    </Dialog>;
};
export default ChatRestrictions;