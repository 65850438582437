import { ProgressBar } from '@/components/ui/Progress';
import { useTranslations } from '@/hooks';
import { cn, getPopularityColor } from '@/utils';
export const PushProgress = ({
  pushCount = 0,
  pushRange,
  popularity = 0,
  showFeedback = true,
  className
}) => {
  const t = useTranslations();
  const getPushPercentage = () => {
    return (pushCount / pushRange.high * 100).toFixed(2);
  };
  const getPushProgressFeedback = () => {
    const pushPercentage = getPushPercentage();
    let feedbackTier = 'lowest';
    if (pushPercentage >= 10 && pushPercentage < 25) {
      feedbackTier = 'low';
    } else if (pushPercentage >= 25 && pushPercentage <= 50) {
      feedbackTier = 'medium';
    } else if (pushPercentage > 50 && pushPercentage <= 75) {
      feedbackTier = 'high';
    } else if (pushPercentage > 75 && pushPercentage <= 99) {
      feedbackTier = 'almost_there';
    } else if (pushPercentage > 99) {
      feedbackTier = 'done';
    }
    const feedbackTitle = t(`match.common.progress.${feedbackTier}.title`);
    const feedbackText = t(`match.common.progress.${feedbackTier}.text`);
    return {
      title: feedbackTitle,
      text: feedbackText
    };
  };
  const classes = cn({
    [className]: className
  });
  return <div className={classes} data-sentry-component="PushProgress" data-sentry-source-file="PushProgress.jsx">
      <ProgressBar variant={getPopularityColor(popularity)} label={t('match.common.progress.push_count')} valueLabel={`${pushCount} (${getPushPercentage()}%)`} max={pushRange.high} value={pushCount} className="mb-4" data-sentry-element="ProgressBar" data-sentry-source-file="PushProgress.jsx" />

      {showFeedback && <div className="p-4 rounded-lg bg-secondary border-outline">
          <h3 className="text-sm font-semibold">
            {getPushProgressFeedback().title}
          </h3>

          <p className="text-sm">{getPushProgressFeedback().text}</p>
        </div>}
    </div>;
};
export default PushProgress;