'use client';

import { useInfiniteQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { matchfyApi } from '@/api';
import { SearchCommandTrigger, SearchCuratorsTab, SearchNavigationTab, SearchPlaylistsTab, SearchTabs } from '@/components/Search';
import { CommandDialog, CommandInput, CommandList } from '@/components/ui/Command/Command';
import { SIDEBAR_PRIMARY, SIDEBAR_SECONDARY, SEARCH_TYPES, PLANS } from '@/data';
import { useTranslations, useSession } from '@/hooks';
import { useRouter } from '@/i18n/routing';
export const SearchCommand = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [activeTab, setActiveTab] = useState(SEARCH_TYPES.NAVIGATION);
  const {
    data: session
  } = useSession();
  const user = session?.user || {};
  const isArtist = user?.plan?.name === PLANS.ARTIST;
  const isAgency = user?.plan?.name === PLANS.AGENCY;
  const router = useRouter();
  const commandRef = useRef(null);
  const searchInputRef = useRef(null);
  const t = useTranslations();
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  const items = [...SIDEBAR_PRIMARY, ...SIDEBAR_SECONDARY];
  const debouncedSearch = useMemo(() => debounce(value => {
    setSearch(value);
  }, 300), []);
  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: ['search', search, activeTab],
    queryFn: async params => {
      if (!search || search.length < 5) return [];
      const res = await matchfyApi.listSearchResults({
        ...params,
        type: activeTab,
        query: search
      });
      return res;
    },
    getNextPageParam: lastPage => {
      if (!lastPage?.nextPage) return;
      return lastPage.nextPage;
    },
    enabled: search.length >= 2 && (isArtist || isAgency)
  });
  useEffect(() => {
    const down = e => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(open => !open);
        return;
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [open, items]);
  useEffect(() => {
    const globalShortcut = e => {
      if (!open) return;

      // Block shortcuts if user is typing in search
      if (isSearchFocused) return;

      // Check if the key pressed matches any item shortcut (without requiring CMD/CTRL)
      const key = e.key.toUpperCase();
      const matchingItem = items.flat().find(item => item?.shortcut === key);
      if (matchingItem) {
        e.preventDefault();
        e.stopPropagation();
        handleSelect(matchingItem.path);
        return false;
      }
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target.isContentEditable) {
        return;
      }
    };
    document.addEventListener('keydown', globalShortcut, {
      capture: true
    });
    return () => document.removeEventListener('keydown', globalShortcut, {
      capture: true
    });
  }, [items, open, isSearchFocused]);
  useEffect(() => {
    if (inView && hasNextPage && !isLoading) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isLoading]);
  const handleSelect = href => {
    setOpen(false);
    router.push(href);
  };
  const handleOpen = open => {
    setOpen(open);
    setIsSearchFocused(false);
  };
  useEffect(() => {
    if (open && commandRef && activeTab === SEARCH_TYPES.NAVIGATION) {
      setTimeout(() => {
        commandRef.current?.focus();
      }, 250);
    }
  }, [open]);
  useEffect(() => {
    if (searchInputRef && activeTab !== SEARCH_TYPES.NAVIGATION) {
      searchInputRef.current?.focus();
    } else {
      commandRef.current?.focus();
    }
  }, [activeTab]);
  const searchResults = data?.pages?.flatMap(page => page.data || []) || [];
  return <>
      <SearchCommandTrigger handleOpen={handleOpen} data-sentry-element="SearchCommandTrigger" data-sentry-source-file="SearchCommand.jsx" />

      <CommandDialog title={t('common.labels.search')} open={open} onOpenChange={handleOpen} data-sentry-element="CommandDialog" data-sentry-source-file="SearchCommand.jsx">
        <CommandInput ref={searchInputRef} placeholder={t('search.command_placeholder')} onValueChange={debouncedSearch} autoFocus={false} onFocus={() => setIsSearchFocused(true)} onBlur={() => setIsSearchFocused(false)} data-sentry-element="CommandInput" data-sentry-source-file="SearchCommand.jsx" />

        <SearchTabs activeTab={activeTab} setActiveTab={setActiveTab} data-sentry-element="SearchTabs" data-sentry-source-file="SearchCommand.jsx" />

        <CommandList ref={commandRef} className="md:max-h-[600px]" data-sentry-element="CommandList" data-sentry-source-file="SearchCommand.jsx">
          {activeTab === SEARCH_TYPES.NAVIGATION && <SearchNavigationTab items={items} setOpen={setOpen} />}

          {activeTab === SEARCH_TYPES.CURATORS && <SearchCuratorsTab isLoading={isLoading} isFetching={isFetching} results={searchResults} scrollRef={ref} search={search} setOpen={setOpen} />}

          {activeTab === SEARCH_TYPES.PLAYLISTS && <SearchPlaylistsTab isLoading={isLoading} isFetching={isFetching} results={searchResults} scrollRef={ref} search={search} setOpen={setOpen} />}
        </CommandList>
      </CommandDialog>
    </>;
};
export default SearchCommand;