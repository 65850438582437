'use client';

import React from 'react';
import { PlaylistCell } from '@/components/Playlist';
import { PLAYLIST_COVER_DATA } from '@/data';
import { useTranslations } from '@/hooks';
import { splitArray, shuffle, cn } from '@/utils';
export const AuthBackground = () => {
  const t = useTranslations();
  const IMAGE_ROWS = 10;
  const playlists = PLAYLIST_COVER_DATA.map((playlist, idx) => {
    return {
      title: playlist.title,
      image: `/images/covers/cover_${++idx}.jpg`
    };
  });
  const playlistRows = splitArray(shuffle(playlists), IMAGE_ROWS);
  const classes = cn({
    'auth-bg': true,
    'relative flex flex-col gap-4 overflow-hidden rounded-r-xl border-l p-6 bg-secondary': true
  });
  return <div className={classes} data-sentry-component="AuthBackground" data-sentry-source-file="AuthBackground.jsx">
      <div className="flex gap-3 auth-bg-images">
        <div className="z-0 opacity-10 overlay overlay-primary" />

        {playlistRows.map((row, i) => <div key={i} className="flex flex-col gap-3">
            {row.map(playlist => <PlaylistCell key={playlist.title} {...playlist} hideTitle overlay={false} className="playlist-cell shrink-0" />)}
          </div>)}
      </div>
    </div>;
};
export default AuthBackground;