'use client';

import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import { CommandItem } from '@/components/ui/Command/Command';
import { generateRoute, ROUTES } from '@/data';
import { useRouter } from '@/i18n/routing';
export const CuratorCommandItem = ({
  result,
  setOpen
}) => {
  const router = useRouter();
  const curatorLink = generateRoute(ROUTES.CURATOR_DETAIL, {
    id: result.id
  });
  const handleSelect = href => {
    setOpen(false);
    router.push(href);
  };
  return <CommandItem key={result.id} id={result.id} value={result.name} keywords={[result.id, result.name, result?.star ? 'star' : '']} onSelect={() => handleSelect(curatorLink)} className="gap-3" data-sentry-element="CommandItem" data-sentry-component="CuratorCommandItem" data-sentry-source-file="CuratorCommandItem.jsx">
      <Avatar src={result.image} alt={result.name} size="sm" star={result?.star} className="object-cover w-8 h-8 rounded-full" data-sentry-element="Avatar" data-sentry-source-file="CuratorCommandItem.jsx" />

      <div className="flex items-center flex-1 gap-2">
        <div className="flex flex-col items-start">
          <span className="text-sm">
            {result.name}
          </span>

          <span className="text-xs text-muted-foreground">
            @{result.id}
          </span>
        </div>

        {result.star && <Badge size="sm" variant="gold-light" label="Star" />}
      </div>
    </CommandItem>;
};
export default CuratorCommandItem;