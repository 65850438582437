import { ProgressBar } from '@/components/ui/Progress';
import { useTranslations } from '@/hooks';
import { cn, getPopularityColor } from '@/utils';
export const TrackPopularityBar = ({
  popularity = 0,
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    [className]: className
  });
  return <div className={classes} data-sentry-component="TrackPopularityBar" data-sentry-source-file="TrackPopularityBar.jsx">
      <ProgressBar variant={getPopularityColor(popularity)} label={t('common.metrics.popularity')} valueLabel={`${popularity}%`} max={100} value={popularity} data-sentry-element="ProgressBar" data-sentry-source-file="TrackPopularityBar.jsx" />
    </div>;
};
export default TrackPopularityBar;