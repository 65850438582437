'use client';

import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { getAlbumById, getArtistById, getArtists, getPlaylistById, getShowById, getTrackById, getTrackFeatures, searchItem } from '@/api/spotify/endpoints';
import { FieldTooltip } from '@/components/ui/Field/Field';
import { SPOTIFY_ID_LENGTH, PLANS } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { useMatchStore } from '@/store';
import { cn, getSpotifyId, isSpotifyUrl } from '@/utils';
const TYPES = {
  track: {
    param: 'track',
    res: 'tracks',
    img: 'album'
  },
  artist: {
    param: 'artist',
    res: 'artists'
  },
  album: {
    param: 'album',
    res: 'albums'
  },
  playlist: {
    param: 'playlist',
    res: 'playlists'
  },
  show: {
    param: 'show',
    res: 'shows'
  }
};
const SearchSelect = React.forwardRef(({
  id,
  label,
  title,
  defaultValue,
  tooltipText,
  entity = 'track',
  fetchAudioFeatures = false,
  placeholder,
  onChange,
  onDataChange,
  onClear,
  clear,
  isDisabled,
  hints,
  errors,
  queryLimit = 3,
  className,
  limitBy,
  hideLabel,
  artistIds
}, ref) => {
  const [value, setValue] = useState(defaultValue);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [type] = useState(TYPES[entity]);
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const {
    setGenres
  } = useMatchStore();
  const selectClasses = cn({
    'field-input-container': true,
    'search-select': true,
    [`search-select-${entity}`]: entity,
    'field-error': errors,
    'cursor-not-allowed opacity-50': isDisabled,
    [className]: className
  });
  const optionClasses = cn({
    'search-select-option': true,
    [`search-select-option-${entity}`]: entity
  });
  const getItemById = async (id, skipFeedback, urlType) => {
    if (id && id.length === SPOTIFY_ID_LENGTH) {
      try {
        let data;
        switch (entity) {
          case 'track':
            if (urlType === 'album') {
              const albumData = await getAlbumById(id);
              if (!albumData || albumData.album_type !== 'single') {
                throw new Error('album_not_allowed');
              }
              data = {
                ...albumData.tracks.items[0],
                album: albumData
              };
            } else {
              data = await getTrackById(id);
            }
            if (fetchAudioFeatures) {
              const audioFeatures = await getTrackFeatures(id);
              data.audioFeatures = audioFeatures;
            }
            if (user.plan.name === PLANS.AGENCY) {
              const trackArtists = data.artists.map(artist => artist.id);
              const artistData = await getArtists(trackArtists);
              const artistsGenres = [...new Set(artistData.artists.map(artist => artist.genres).flat())];
              if (artistsGenres?.length > 0) {
                setGenres(artistsGenres);
              }
            }
            break;
          case 'artist':
            data = await getArtistById(id);
            break;
          case 'playlist':
            data = await getPlaylistById(id);
            break;
          case 'show':
            data = await getShowById(id);
            break;
          default:
            break;
        }
        if (data) {
          if (limitBy === 'artist' && artistIds.length > 0 && !data.artists.some(artist => artistIds.includes(artist.id))) {
            throw new Error('artist_not_allowed');
          }
          const newOptions = [{
            value: data.id,
            label: data.name,
            img: data[type.img]?.images[0]?.url || data.images[0]?.url || null,
            data
          }];
          setOptions(newOptions);
          if (newOptions && newOptions.length > 0) {
            setValue(newOptions[0]);
            if (onChange) {
              onChange(newOptions[0].value);
            }
            if (onDataChange) {
              onDataChange(data);
            }
            if (!skipFeedback) {
              toast('success', t(`feedback.success.search_select.${entity}`));
            }
          }
          return newOptions;
        }
      } catch (error) {
        if (error.message === 'artist_not_allowed') {
          toast('error', t(`feedback.error.search_select.artist_not_allowed`));
        } else {
          toast('error', t(`feedback.error.search_select.not_found`, {
            entity
          }));
        }
      }
    }
    return [];
  };
  const loadOptions = async query => {
    if (query.length >= SPOTIFY_ID_LENGTH || isSpotifyUrl(query)) {
      return false;
    }
    if (query && query.length >= queryLimit) {
      const res = await searchItem(query, type.param);
      const data = res[type.res];
      if (data && data?.items.length > 0) {
        const newOptions = data.items.filter(item => {
          if (limitBy === 'artist' && artistIds?.length > 0 && item) {
            return item.artists.some(artist => artist && artistIds.includes(artist.id));
          }
          return true;
        }).map(item => ({
          value: item?.id || '',
          label: item?.name || '',
          img: item[type.img]?.images?.[0]?.url || item.images?.[0]?.url || null,
          data: item
        }));
        setOptions(newOptions);
        return newOptions;
      }
    }
    return [];
  };
  const debouncedLoadOptions = useCallback(debounce(query => loadOptions(query), 5000), [query]);
  const Option = props => {
    const {
      data
    } = props;
    return <components.Option {...props} data-sentry-element="unknown" data-sentry-component="Option" data-sentry-source-file="SearchSelect.jsx">
          <div className={optionClasses}>
            {data.img && data.img.length > 0 && <img src={data.img} alt={data.label} />}
            <span>{data.label}</span>
          </div>
        </components.Option>;
  };
  const SingleValue = props => {
    const {
      data
    } = props;
    return <components.SingleValue {...props} data-sentry-element="unknown" data-sentry-component="SingleValue" data-sentry-source-file="SearchSelect.jsx">
          <div className={optionClasses}>
            {data.img && data.img.length > 0 && <img src={data.img} alt={data.label} />}
            <span>{data.label}</span>
          </div>
        </components.SingleValue>;
  };
  const handleSelectChange = (option, action) => {
    if (action.action === 'clear') {
      if (onClear) onClear();
      setValue(null);
    }
    if (option) {
      setValue(option);
    }
    if (option && onDataChange) {
      onDataChange(option.data);
    }
    if (option && onChange) {
      onChange(option.value);
    } else if (onChange) {
      onChange(null);
    }
  };
  const handleInputChange = (inputValue, action) => {
    if (inputValue.length >= SPOTIFY_ID_LENGTH || isSpotifyUrl(inputValue)) {
      const _isSpotifyUrl = isSpotifyUrl(inputValue);
      const spotifyData = _isSpotifyUrl ? getSpotifyId(inputValue) : undefined;
      const spotifyId = _isSpotifyUrl ? spotifyData.id : inputValue;
      const spotifyUrlType = _isSpotifyUrl ? spotifyData.type : 'track';
      getItemById(spotifyId, false, spotifyUrlType);
    } else if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setQuery(inputValue);
    }
  };
  useEffect(() => {
    if (query) {
      debouncedLoadOptions(query);
    }
  }, [query, debouncedLoadOptions]);
  useEffect(() => {
    if (defaultValue) {
      getItemById(defaultValue, true);
    }
  }, [defaultValue]);
  useEffect(() => {
    if (clear) {
      setValue(null);
    }
  }, [clear]);
  return <div id={id} className={selectClasses}>
        {title && <h5 className="flex items-center gap-2">
            {title}
            {tooltipText && <FieldTooltip tooltipText={tooltipText} />}
          </h5>}

        <label className="flex items-center gap-2">
          {!label && !hideLabel && t(`field.search_select.${type.param}.label`)}
          {label && label}
          {tooltipText && !title && <FieldTooltip tooltipText={tooltipText} />}
        </label>

        <AsyncSelect ref={ref} className="select" classNamePrefix="select" components={{
      Option,
      SingleValue
    }} cacheOptions={true} isClearable={true} isDisabled={isDisabled} menuPortalTarget={document.querySelector('#root')} inputValue={query} onInputChange={handleInputChange} options={options} onChange={handleSelectChange} label={label} loadOptions={loadOptions} value={value} noOptionsMessage={() => t(`field.search_select.${type.param}.no_options`)} placeholder={placeholder ? placeholder : t(`field.search_select.${type.param}.placeholder`)} />

        {hints && <div className="hints-container">
            {hints.map(hint => <p key={hint} className="info-message">
                {hint}
              </p>)}
          </div>}

        {errors && Array.isArray(errors) && <div className="errors-container">
            {errors.map(error => <p key={error} className="error-message">
                {error}
              </p>)}
          </div>}
      </div>;
});
export default SearchSelect;