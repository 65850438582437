import Button from '@/components/ui/Button';
import { useTranslations } from '@/hooks';
function PushButton({
  className,
  disabled,
  loading,
  onClick
}) {
  const t = useTranslations();
  return <div className={className ?? null} data-sentry-component="PushButton" data-sentry-source-file="PushButton.jsx">
      <p>{t('match.spotify.push_to_playlist.text')}</p>

      <Button disabled={disabled} loading={loading} variant="gradient" className="mt-3" label={t('match.spotify.push_to_playlist.cta')} onClick={onClick} data-sentry-element="Button" data-sentry-source-file="PushButton.jsx" />
    </div>;
}
export default PushButton;