import { LogoIconPlain } from '@/components/ui/Logo/Logo';
import { cn } from '@/utils';
const PlaylistCell = ({
  title,
  image,
  overlay = true,
  hideTitle = false,
  className
}) => {
  return <div key={title} className={cn(className)} data-sentry-component="PlaylistCell" data-sentry-source-file="PlaylistCell.jsx">
      {image && <img src={image} alt={title} className="absolute inset-0 object-cover" />}

      <LogoIconPlain className="absolute z-20 w-3 h-3 text-white top-3 right-3 backdrop-blur-lg opacity-70" data-sentry-element="LogoIconPlain" data-sentry-source-file="PlaylistCell.jsx" />

      {overlay && <div className="overlay overlay-bottom" />}

      {!hideTitle && <span className="absolute bottom-2 left-3 leading-tight text-white text-xs md:text-sm font-bold text-balance max-w-[60%] z-20 opacity-90">
          {title}
        </span>}
    </div>;
};
export default PlaylistCell;