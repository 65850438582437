'use client';

import { useState } from 'react';
import { CuratorDetailPopup } from '@/components/Curator/CuratorDetailPopup';
import Avatar from '@/components/ui/Avatar';
import { cn } from '@/utils';
export const CuratorInfo = ({
  curator,
  fallbackText = '-',
  className = ''
}) => {
  const [currentItem, setCurrentItem] = useState(null);
  const handleCuratorClick = () => {
    if (!curator.id) {
      return;
    }
    setCurrentItem(curator);
  };
  const classes = cn({
    'cursor-not-allowed': !curator.id,
    [className]: className
  });
  return <div className="flex flex-col items-start" data-sentry-component="CuratorInfo" data-sentry-source-file="CuratorInfo.jsx">
      <div className={cn('inline-flex items-center gap-2 cursor-pointer', classes)} onClick={handleCuratorClick}>
        <Avatar src={curator.avatar} label={curator.id} size="xs" data-sentry-element="Avatar" data-sentry-source-file="CuratorInfo.jsx" />

        {curator.spotifyUsername ? <span className="font-medium text-truncate">
            {curator.spotifyUsername}
          </span> : <span className="text-muted-foreground">
            {fallbackText}
          </span>}
      </div>

      <CuratorDetailPopup curator={currentItem} open={!!currentItem} handleModalClose={() => setCurrentItem(null)} data-sentry-element="CuratorDetailPopup" data-sentry-source-file="CuratorInfo.jsx" />
    </div>;
};
export default CuratorInfo;