import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { PLANS } from '@/data';
import { useTranslations } from '@/hooks';
import { planRedirect } from '@/utils';
export const MonthlyPushLimitPopup = ({
  open,
  setOpen
}) => {
  const t = useTranslations();
  return <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-component="MonthlyPushLimitPopup" data-sentry-source-file="MonthlyPushLimitPopup.jsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="MonthlyPushLimitPopup.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="MonthlyPushLimitPopup.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="MonthlyPushLimitPopup.jsx">{t('errors.push.plan_limit_reached')}</DialogTitle>
        </DialogHeader>

        <h4 className="mb-3 text-2xl">
          {t('errors.push.plan_limit_reached_text.0', {
          shouldParse: true
        })}
        </h4>

        <p className="mb-3">
          {t('errors.push.plan_limit_reached_text.1', {
          shouldParse: true
        })}
        </p>

        <Button variant="gradient" className="w-full mt-3" href={planRedirect(PLANS.ARTIST)} label={t('navigation.pricing')} data-sentry-element="Button" data-sentry-source-file="MonthlyPushLimitPopup.jsx" />
      </DialogContent>
    </Dialog>;
};
export default MonthlyPushLimitPopup;