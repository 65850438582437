import Badge from '@/components/ui/Badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const PlaylistGenresOverview = ({
  genres,
  maxVisible = 10,
  maxTotal = 20,
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    'flex flex-wrap gap-1': true,
    [className]: className
  });
  const visibleGenres = genres.slice(0, maxVisible);
  const remainingGenres = genres.slice(maxVisible, maxTotal);
  const hiddenGenres = genres.length > maxTotal ? genres.length - maxTotal : 0;
  const hasMoreGenres = remainingGenres.length > 0 || hiddenGenres > 0;
  return <div className={classes} data-sentry-component="PlaylistGenresOverview" data-sentry-source-file="PlaylistGenresOverview.jsx">
      {visibleGenres.map(genre => <Badge key={genre} label={genre} variant="light" />)}

      {hasMoreGenres && <Tooltip>
          <TooltipTrigger>
            <Badge label={`+${remainingGenres.length + hiddenGenres}`} variant="light" />
          </TooltipTrigger>
          <TooltipContent className="max-w-[320px] py-3" side="bottom">
            <div className="flex flex-wrap gap-1">
              {remainingGenres.map(genre => <Badge key={genre} label={genre} variant="primary-light" />)}

              {hiddenGenres > 0 && <Badge label={t('common.labels.other_n_genres', {
            n: hiddenGenres
          })} variant="primary-light" />}
            </div>
          </TooltipContent>
        </Tooltip>}
    </div>;
};
export default PlaylistGenresOverview;