import { nanoid } from 'nanoid';
import { matchfyApi } from '@/api';
import Badge from '@/components/ui/Badge';
import Image from '@/components/ui/Image';
import { Placeholder } from '@/components/ui/Placeholder';
import { generateRoute, ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
export default function CuratorPlaylistCard({
  playlist
}) {
  const t = useTranslations();
  if (!playlist || !playlist.public) {
    return null;
  }
  return <Link href={generateRoute(ROUTES.CURATOR_PLAYLIST_DETAIL, {
    id: playlist?.id
  })} key={nanoid()} className="curator-playlist hoverable" data-sentry-element="Link" data-sentry-component="CuratorPlaylistCard" data-sentry-source-file="CuratorPlaylistCard.jsx">
      <div className="relative w-full aspect-square shrink-0">
        {playlist?.image ? <Image src={playlist?.image} placeholder={<Placeholder size="sm" icon="Music" />} alt={playlist?.name} className="relative w-full overflow-hidden rounded-md aspect-square shrink-0" imageClasses="absolute inset-0 object-cover w-full h-full" fetchFn={() => matchfyApi.getPlaylistImage(playlist?.id)} /> : <Placeholder icon="Music" size="lg" className="w-full" />}
      </div>

      <div className="flex flex-col items-start w-full h-full gap-2">
        <h3>{playlist?.name}</h3>
        <Badge icon="UsersRound" label={t('common.metrics.followers')} value={playlist?.followers || `0`} variant="light" data-sentry-element="Badge" data-sentry-source-file="CuratorPlaylistCard.jsx" />
      </div>
    </Link>;
}