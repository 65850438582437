import PlaylistSelectGenres from './PlaylistSelectGenres';
import { PlaylistGenres } from '@/components/Playlist/PlaylistGenres';
import { usePlaylistStore } from '@/store';
import { cn } from '@/utils';
export const PlaylistSettings = ({
  className,
  closeModalFn
}) => {
  const classes = cn({
    [className]: className
  });
  const {
    currentPlaylistId,
    currentPlaylist
  } = usePlaylistStore();
  return <div className={classes} data-sentry-component="PlaylistSettings" data-sentry-source-file="PlaylistSettings.jsx">
      {currentPlaylistId && !currentPlaylist.enableManualGenres && <PlaylistGenres className="w-full" closeModalFn={closeModalFn} />}

      {currentPlaylistId && currentPlaylist.enableManualGenres && <PlaylistSelectGenres className="w-full" closeModalFn={closeModalFn} />}
    </div>;
};
export default PlaylistSettings;