import { useState } from 'react';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogTitle } from "@/components/ui/Dialog";
import Field from '@/components/ui/Field';
import Separator from '@/components/ui/Separator';
import Spinner from '@/components/ui/Spinner';
import { Switch } from '@/components/ui/Switch';
import { AUTO_ACCEPT_MAX_MATCH_RATE, AUTO_ACCEPT_MIN_MATCH_RATE } from '@/data';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { usePlaylistStore } from '@/store';
export default function AutoAcceptPopup({
  open,
  defaultValue,
  playlistId,
  onClose,
  updatePlaylistData
}) {
  const [minMatchRate, setMinMatchRate] = useState(defaultValue);
  const [enable, setEnable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslations();
  const {
    autoAcceptEnable,
    autoAcceptDisable
  } = usePlaylistStore();
  const handleMinMatchRateChange = value => {
    setMinMatchRate(value?.[0]);
  };
  const handleEnableAutoSubmit = async () => {
    setIsLoading(true);
    if (enable) {
      let rate = minMatchRate;
      if (minMatchRate < AUTO_ACCEPT_MIN_MATCH_RATE) {
        setMinMatchRate(AUTO_ACCEPT_MIN_MATCH_RATE);
        rate = AUTO_ACCEPT_MIN_MATCH_RATE;
      } else if (minMatchRate > AUTO_ACCEPT_MAX_MATCH_RATE) {
        setMinMatchRate(AUTO_ACCEPT_MAX_MATCH_RATE);
        rate = AUTO_ACCEPT_MAX_MATCH_RATE;
      }
      try {
        await autoAcceptEnable(playlistId, rate);
        updatePlaylistData(playlistId, {
          autoAcceptRate: rate
        });
        if (onClose) {
          onClose(rate);
        }
        setIsLoading(false);
      } catch (error) {
        toast('error', t('feedback.error.default'));
      }
    } else {
      try {
        await autoAcceptDisable(playlistId);
        updatePlaylistData(playlistId, {
          autoAcceptRate: 100
        });
        if (onClose) {
          onClose(100);
        }
        setIsLoading(false);
      } catch (error) {
        toast('error', t('feedback.error.default'));
      }
    }
  };
  return <Dialog open={open} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="AutoAcceptPopup" data-sentry-source-file="AutoAcceptPopup.jsx">
      <DialogContent size="sm" onClose={onClose} data-sentry-element="DialogContent" data-sentry-source-file="AutoAcceptPopup.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="AutoAcceptPopup.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="AutoAcceptPopup.jsx">
            <div className="flex items-center gap-3">
              {t('playlist.settings.auto_accept.title')}
              <Badge label="AI" icon="Sparkles" variant="blue-light" data-sentry-element="Badge" data-sentry-source-file="AutoAcceptPopup.jsx" />
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col items-start">
          <Badge icon="Bot" iconType="brand" label="βeta Feature" variant="blue-light" className="mb-3" data-sentry-element="Badge" data-sentry-source-file="AutoAcceptPopup.jsx" />

          <h3 className="mb-2 text-xl md:text-3xl">
            {t('playlist.settings.auto_accept.intro.title')}
          </h3>

          {t('playlist.settings.auto_accept.intro.description')}
        </div>

        <Separator className="my-6" data-sentry-element="Separator" data-sentry-source-file="AutoAcceptPopup.jsx" />

        <form className="form">
          <div className="form-group">
            <label>{t('playlist.settings.auto_accept.enable')}</label>
            <Switch checked={enable} onCheckedChange={() => setEnable(!enable)} name="enable-auto-submit" data-sentry-element="Switch" data-sentry-source-file="AutoAcceptPopup.jsx" />
          </div>

          <div className="form-group">
            <label htmlFor="auto-submit-match-rate">
              {t('playlist.settings.auto_accept.input')}
            </label>

            <Field type="slider" showLabels={true} labelSuffix="%" variant="blue" min={AUTO_ACCEPT_MIN_MATCH_RATE} max={AUTO_ACCEPT_MAX_MATCH_RATE} disabled={!enable} id="auto-submit-match-rate" defaultValue={[minMatchRate]} value={[minMatchRate]} onChange={handleMinMatchRateChange} data-sentry-element="Field" data-sentry-source-file="AutoAcceptPopup.jsx" />
          </div>
        </form>

        <DialogFooter className="mt-6" data-sentry-element="DialogFooter" data-sentry-source-file="AutoAcceptPopup.jsx">
          <Button className="w-full" variant="blue-light" onClick={handleEnableAutoSubmit} data-sentry-element="Button" data-sentry-source-file="AutoAcceptPopup.jsx">
            {isLoading ? <Spinner inline /> : t('playlist.settings.auto_accept.cta')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>;
}