'use client';

import React, { useEffect, useState } from 'react';
import PushProgress from './PushProgress';
import { TrackPopularityBadge, TrackPopularityBar } from '@/components/Track';
import Alert from '@/components/ui/Alert';
import Badge from '@/components/ui/Badge';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import Separator from '@/components/ui/Separator';
import { useTranslations } from '@/hooks';
import { calculatePushRange, getPopularityColor, formatNumber } from '@/utils';
export const PushCounter = ({
  pushCount,
  popularity
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [pushRange, setPushRange] = useState({
    low: 0,
    high: 0
  });
  const t = useTranslations();
  const getPopularityRange = () => {
    if (popularity < 30) {
      return 'low';
    } else if (popularity >= 30 && popularity <= 70) {
      return 'medium';
    }
    return 'high';
  };
  const getFeedback = () => {
    const popularityRange = getPopularityRange();
    const feedbackTitle = t(`track.popularity.levels.${popularityRange}.title`);
    const feedbackText = t(`track.popularity.levels.${popularityRange}.text`);
    return {
      title: feedbackTitle,
      text: feedbackText
    };
  };
  useEffect(() => {
    setPushRange(calculatePushRange(popularity));
  }, [popularity]);
  if (!pushCount || !popularity) {
    return null;
  }
  return <div className="flex flex-wrap items-center gap-3" data-sentry-component="PushCounter" data-sentry-source-file="PushCounter.jsx">
      {pushCount && parseInt(pushCount) > 0 ? <Badge variant="primary-light" label={t('match.common.progress.push_count')} value={parseInt(pushCount) > 0 ? pushCount : `0 push`} icon="ArrowRight" iconRight="Info" onClick={() => setShowFeedback(true)} /> : null}

      {popularity && parseInt(popularity) > 0 ? <TrackPopularityBadge popularity={popularity} /> : null}

      <Dialog open={showFeedback} onOpenChange={setShowFeedback} data-sentry-element="Dialog" data-sentry-source-file="PushCounter.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="PushCounter.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PushCounter.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PushCounter.jsx">{getFeedback().title}</DialogTitle>
          </DialogHeader>

          <TrackPopularityBar popularity={popularity} data-sentry-element="TrackPopularityBar" data-sentry-source-file="PushCounter.jsx" />

          <p className="my-4">{getFeedback().text}</p>

          <PushProgress pushCount={pushCount} pushRange={pushRange} popularity={popularity} data-sentry-element="PushProgress" data-sentry-source-file="PushCounter.jsx" />

          <Alert color={getPopularityColor(popularity)} className="my-4" data-sentry-element="Alert" data-sentry-source-file="PushCounter.jsx">
            <p className="text-xl">
              {t('track.popularity.range')}:{' '}
              {formatNumber(pushRange.low)} - {formatNumber(pushRange.high)}
            </p>
          </Alert>

          <Separator className="my-4" data-sentry-element="Separator" data-sentry-source-file="PushCounter.jsx" />

          <p className="text-sm opacity-70">
            {t('track.popularity.notes')}
          </p>
        </DialogContent>
      </Dialog>
    </div>;
};
export default PushCounter;