'use client';

import dayjs from 'dayjs';
import { useState } from 'react';
import { matchfyApi } from '@/api';
import CuratorPromote from '@/components/Curator/CuratorPromote';
import { PlaylistsTable } from '@/components/Playlist';
import Button from '@/components/ui/Button';
import { useLocale, useSession, useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { getDateFormat } from '@/utils';
function CuratorPlaylists() {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const [playlistTableKey, setPlaylistTableKey] = useState('playlist_table_key');
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(dayjs().format(getDateFormat(locale, true)));
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const fetchPlaylists = async () => {
    try {
      setUpdateLoading(true);
      await matchfyApi.fetchPlaylists();
      setUpdatedAt(dayjs().format(getDateFormat(locale, true)));
      toast('success', t('feedback.success.playlist.fetch'));
    } catch (error) {
      console.error(error);
      toast('error', t('feedback.error.default'));
    } finally {
      setTimeout(() => {
        setUpdateLoading(false);
      }, 500);
    }
  };
  return <div className="curator" data-sentry-component="CuratorPlaylists" data-sentry-source-file="CuratorPlaylists.jsx">
      <div className="flex flex-col gap-3 mb-4 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex items-center gap-2">
          <Button small icon="RefreshCcw" variant="light" loading={updateLoading} onClick={fetchPlaylists} data-sentry-element="Button" data-sentry-source-file="CuratorPlaylists.jsx">
            {t('common.actions.update')}
          </Button>

          <span className="text-sm opacity-70">
            {t('common.time.last_update')}
            {': '}
            {updatedAt}
          </span>
        </div>

        <div className="flex flex-col gap-2 md:flex-row">
          <CuratorPromote triggerVariant="primary-light" data-sentry-element="CuratorPromote" data-sentry-source-file="CuratorPlaylists.jsx" />
        </div>
      </div>

      <PlaylistsTable key={playlistTableKey} userId={user.spotifyId} isUserVerified={user.verified} data-sentry-element="PlaylistsTable" data-sentry-source-file="CuratorPlaylists.jsx" />
    </div>;
}
export default CuratorPlaylists;