import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
export const Placeholder = ({
  className,
  variant,
  icon,
  size,
  label,
  star,
  ...props
}) => {
  const classes = cn({
    placeholder: true,
    [`placeholder-${variant}`]: variant,
    [size]: size,
    'avatar-star': star,
    [className]: className
  });
  return <div className={classes} {...props} data-sentry-component="Placeholder" data-sentry-source-file="Placeholder.jsx">
      {icon && <Icon className="flex items-center justify-center" name={icon} />}

      {label && <p>{label}</p>}
    </div>;
};
export default Placeholder;