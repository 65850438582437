import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const ChatArchiveToggle = ({
  showArchive,
  setShowArchive,
  total
}) => {
  const t = useTranslations();
  return <div className={cn({
    'chat-archive': true,
    'chat-list-item': true,
    active: showArchive
  })} onClick={() => setShowArchive(!showArchive)} data-sentry-component="ChatArchiveToggle" data-sentry-source-file="ChatArchiveToggle.jsx">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          <Avatar icon={showArchive ? 'ArrowLeft' : 'Archive'} data-sentry-element="Avatar" data-sentry-source-file="ChatArchiveToggle.jsx" />
          <span className="inline-flex items-center gap-2 font-medium">
            {t('chat.archived_chats')}
          </span>
        </div>

        <Badge variant={showArchive ? 'primary' : 'light'} label={total} data-sentry-element="Badge" data-sentry-source-file="ChatArchiveToggle.jsx" />
      </div>
    </div>;
};
export default ChatArchiveToggle;