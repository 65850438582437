'use client';

import Button from '@/components/ui/Button';
export const MatchSubmit = ({
  btnLabel,
  btnVariant,
  className,
  disabled,
  loading,
  onSubmit
}) => {
  return <div className={className} data-sentry-component="MatchSubmit" data-sentry-source-file="MatchSubmit.jsx">
      <Button icon="ListMusic" variant={btnVariant} onClick={onSubmit} className="w-full" label={btnLabel} disabled={disabled} loading={loading} data-sentry-element="Button" data-sentry-source-file="MatchSubmit.jsx" />
    </div>;
};
export default MatchSubmit;