'use client';

import { isMobile } from 'react-device-detect';
import Icon from '@/components/ui/Icon';
import { useTranslations } from '@/hooks';
export const SearchCommandTrigger = ({
  handleOpen
}) => {
  const t = useTranslations();
  return <button onClick={() => handleOpen(true)} className="flex items-center w-full gap-2 px-4 py-3 text-xs border rounded-md h-9 border-input bg-primary" data-sentry-component="SearchCommandTrigger" data-sentry-source-file="SearchCommandTrigger.jsx">
      <span className="flex items-center gap-2">
        <Icon name="Search" className="w-4 h-4 opacity-50 shrink-0" data-sentry-element="Icon" data-sentry-source-file="SearchCommandTrigger.jsx" />
        {!isMobile && <span className="hidden sm:block text-muted-foreground">
            {t('search.placeholder')}
          </span>}
      </span>

      {!isMobile && <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground ml-auto">
          <span className="text-xs">⌘</span>K
        </kbd>}
    </button>;
};
export default SearchCommandTrigger;