'use client';

import { PlaylistCommandItem } from './PlaylistCommandItem';
import Paywall from '@/components/Paywall';
import { CommandGroup, CommandEmpty } from '@/components/ui/Command/Command';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { PLANS, ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
export const SearchPlaylistsTab = ({
  results = [],
  isFetching = false,
  isLoading = false,
  search = '',
  setOpen,
  scrollRef
}) => {
  const t = useTranslations();
  const router = useRouter();
  const handleUpgrade = () => {
    setOpen(false);
    router.push(ROUTES.PRICING);
  };
  return <Paywall btnProps={{
    variant: 'light',
    onClick: handleUpgrade,
    label: "Upgrade"
  }} className="border-0" icon="Lock" plans={[PLANS.ARTIST, PLANS.AGENCY]} message={t('search.upgrade_playlists')} data-sentry-element="Paywall" data-sentry-component="SearchPlaylistsTab" data-sentry-source-file="SearchPlaylistsTab.jsx">
      <CommandGroup className="max-h-[500px] overflow-y-auto scroll-container" data-sentry-element="CommandGroup" data-sentry-source-file="SearchPlaylistsTab.jsx">
        {results.map(result => <PlaylistCommandItem key={result.id} result={result} setOpen={setOpen} />)}
      </CommandGroup>

      {(isLoading || isFetching) && <CommandEmpty key="command-empty">
          <span className="inline-flex items-center gap-2">
            <Spinner inline />
            {t('common.labels.loading')}
          </span>
        </CommandEmpty>}

      {!isLoading && results?.length === 0 && search?.length === 0 && <Empty ghost icon="Search" message={t('search.command_placeholder')} />}

      {!isLoading && results?.length === 0 && search?.length > 0 && <Empty ghost icon="Search" message={t('search.no_results')} />}

      <div ref={scrollRef} />
    </Paywall>;
};
export default SearchPlaylistsTab;