'use client';

import Genres from '@/components/Genres';
import { GENRES_QUERY_LIMIT } from '@/data';
import { cn } from '@/utils';
export const MatchStep2 = ({
  className,
  genres,
  disabled,
  setGenres
}) => {
  return <Genres title="Step 2" className={cn("w-full", className)} genres={genres} maxLimit={GENRES_QUERY_LIMIT} onGenreSelect={genres => setGenres(genres)} disabled={disabled} data-sentry-element="Genres" data-sentry-component="MatchStep2" data-sentry-source-file="MatchStep2.jsx" />;
};
export default MatchStep2;