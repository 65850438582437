import BlockUser from '@/components/BlockUser';
import { ChatStart } from '@/components/Chat';
import CuratorContacts from '@/components/Curator/CuratorContacts';
import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/Dialog';
import { ROUTES, generateRoute } from '@/data';
import { useTranslations } from '@/hooks';
import { buildSpotifyUserUrl, cn } from '@/utils';
export function CuratorDetailPopup({
  curator,
  open,
  className,
  handleModalClose
}) {
  const t = useTranslations();
  const classes = cn({
    'relative': true,
    'overflow-hidden': true,
    [className]: className
  });
  if (!curator) return null;
  const {
    id,
    spotifyUsername,
    avatar,
    contacts
  } = curator;
  return <Dialog open={open} onOpenChange={handleModalClose} data-sentry-element="Dialog" data-sentry-component="CuratorDetailPopup" data-sentry-source-file="CuratorDetailPopup.jsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="CuratorDetailPopup.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="CuratorDetailPopup.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CuratorDetailPopup.jsx">{spotifyUsername}</DialogTitle>
        </DialogHeader>

        <DialogDescription className="hidden" data-sentry-element="DialogDescription" data-sentry-source-file="CuratorDetailPopup.jsx">
          {spotifyUsername}
        </DialogDescription>

        <div className={classes}>
          <div className="flex flex-col items-center gap-4 mb-3 md:flex-row">
            <Avatar src={avatar} alt={spotifyUsername} className="flex-shrink-0 w-20 h-20 rounded-full" data-sentry-element="Avatar" data-sentry-source-file="CuratorDetailPopup.jsx" />

            <div className="flex flex-col items-center w-full md:items-start">
              <h3 className="text-xl text-center sm:text-left sm:mt-2 sm:mb-1">
                {spotifyUsername}
              </h3>

              <div className="grid w-full grid-cols-2 gap-1 mb-3 md:mb-0 md:w-auto md:flex md:flex-row md:items-center md:flex-wrap">
                <Badge variant="light" value={<Button variant="link-plain" href={generateRoute(ROUTES.CURATOR_DETAIL, {
                id
              })} icon="UserRound" target="_blank" rel="noopener noreferrer" label={t('navigation.profile')} />} data-sentry-element="Badge" data-sentry-source-file="CuratorDetailPopup.jsx" />

                <Badge variant="light" value={<Button variant="link-plain" href={buildSpotifyUserUrl(id)} target="_blank" icon="Spotify" iconType="brand" label={t('navigation.spotify.title')} />} data-sentry-element="Badge" data-sentry-source-file="CuratorDetailPopup.jsx" />

                <Badge variant="light" value={<ChatStart inline chatUserId={id} />} data-sentry-element="Badge" data-sentry-source-file="CuratorDetailPopup.jsx" />

                <Badge variant="light" value={<BlockUser variant="link-plain" blockedBtnClasses="text-primary" icon="Ban" checkOnlineStatus spotifyId={id} showTitle={false} showLabel={true} />} data-sentry-element="Badge" data-sentry-source-file="CuratorDetailPopup.jsx" />
              </div>
            </div>
          </div>

          <CuratorContacts contacts={contacts} data-sentry-element="CuratorContacts" data-sentry-source-file="CuratorDetailPopup.jsx" />
        </div>
      </DialogContent>
    </Dialog>;
}
export default CuratorDetailPopup;