'use client';

import { useState, useEffect } from 'react';
export function useTabs({
  tabs,
  initialTabId,
  onChange
}) {
  const getTabIndex = tabId => tabs.findIndex(tab => tab.id === tabId);
  const [[selectedTabIndex, direction], setSelectedTab] = useState(() => {
    const indexOfInitialTab = getTabIndex(initialTabId);
    return [indexOfInitialTab === -1 ? 0 : indexOfInitialTab, 0];
  });
  useEffect(() => {
    const indexOfInitialTab = getTabIndex(initialTabId);
    if (indexOfInitialTab !== -1 && indexOfInitialTab !== selectedTabIndex) {
      setSelectedTab([indexOfInitialTab, 0]);
    }
  }, [initialTabId]);
  return {
    tabProps: {
      tabs,
      selectedTabIndex,
      onChange,
      setSelectedTab
    },
    selectedTab: tabs[selectedTabIndex],
    contentProps: {
      direction,
      selectedTabIndex
    }
  };
}