import Spinner from '@/components/ui/Spinner';
import { cn } from '@/utils';
export const MatchScore = ({
  score
}) => {
  const getScoreColor = () => {
    if (score < 20) return 'red';
    if (score < 40) return 'yellow';
    if (score < 65) return 'orange';
    if (score < 85) return 'green';
    if (score < 100) return 'blue';
    return 'green';
  };
  const classes = cn({
    'card-match-score': true,
    'relative flex flex-col': true,
    'opacity-50': score === -1 || !score
  });
  const progressBarWrapper = cn('h-[5px] w-full bg-gray-700 rounded-full overflow-hidden mt-[4px]');
  const progressBarInner = cn({
    'h-full rounded-full transition-all duration-300': true,
    [`bg-${getScoreColor()}-500`]: true
  });
  return <div className={classes} data-sentry-component="MatchScore" data-sentry-source-file="MatchScore.jsx">
      {score > 0 && <span className="score">
          {score}%
        </span>}

      {(score === -1 || !score) && <span className="score">-</span>}

      {score === 0 && <Spinner inline variant="white" className="mb-1" />}

      <span className="score-label mt-[2px]">
        Match
      </span>

      <div className={progressBarWrapper}>
        <div className={progressBarInner} style={{
        width: `${score > 0 ? score : 0}%`
      }} />
      </div>
    </div>;
};
export default MatchScore;