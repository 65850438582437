import Emoji from '@/components/ui/Emoji';
import { CURATOR_RANGE } from '@/data';
import { cn } from '@/utils';
export default function CuratorEmoji({
  className,
  curator
}) {
  if (!curator?.rankNormalized) return null;
  const {
    rankNormalized
  } = curator;
  const emojiClasses = cn({
    [className]: className
  });
  if (rankNormalized >= CURATOR_RANGE.HIGHEST) {
    return <Emoji className={emojiClasses} symbol="👑" label="Crown" />;
  } else if (rankNormalized >= CURATOR_RANGE.HIGH) {
    return <Emoji className={emojiClasses} symbol="🏆" label="Trophy" />;
  } else if (rankNormalized >= CURATOR_RANGE.MEDIUM) {
    return <Emoji className={emojiClasses} symbol="🏅" label="Sports Medal" />;
  } else if (rankNormalized >= CURATOR_RANGE.LOW) {
    return <Emoji className={emojiClasses} symbol="🔥" label="Fire" />;
  } else if (rankNormalized > CURATOR_RANGE.LOWEST) {
    return <Emoji className={emojiClasses} symbol="😥" label="Sad face" />;
  }
  return <Emoji className={emojiClasses} symbol="😥" label="Sad face" data-sentry-element="Emoji" data-sentry-component="CuratorEmoji" data-sentry-source-file="CuratorEmoji.jsx" />;
}