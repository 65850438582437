import dayjs from 'dayjs';
import { useLocale, useTranslations } from '@/hooks';
import { cn, getDateFormat } from '@/utils';
export const Date = ({
  className,
  dateString,
  format,
  includeTime = false
}) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  if (!dateString) return null;
  const classes = cn({
    [className]: className
  });
  return <time className={classes} dateTime={dateString} data-sentry-component="Date" data-sentry-source-file="Date.jsx">
      {dayjs(dateString).format(getDateFormat(locale, includeTime, format))}
    </time>;
};
export default Date;