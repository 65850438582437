'use client';

import { useEffect } from 'react';
import { AuthButton } from '@/components/Auth';
import AuthVerify from '@/components/Auth/AuthVerify';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { useSession, useTranslations } from '@/hooks';
import { useCreditsStore } from '@/store';
import { cn } from '@/utils';
export default function Auth({
  children,
  className,
  fallback,
  emptyState = false,
  login = false,
  loginLabel,
  loginIcon,
  loginVariant = 'primary',
  wrapperClasses,
  loginClasses,
  redirect,
  plans,
  verify
}) {
  const {
    data: session,
    status
  } = useSession();
  const {
    user
  } = session || {};
  const {
    setCredits
  } = useCreditsStore();
  useEffect(() => {
    if (user) {
      setCredits(user?.referral?.score);
    }
  }, [user]);
  const t = useTranslations();
  if (session && status !== "loading" && !window.intercomSettings?.name) {
    window.intercomSettings = {
      ...window.intercomSettings,
      'Spotify ID': user.spotifyId,
      frontend_version: user.frontend_version,
      name: user.spotifyUsername ?? ''
    };
  }
  if (status === "loading") {
    return <Spinner inline />;
  }
  if (!session && login && !fallback && !emptyState) {
    const loginProps = {
      label: loginLabel ?? t('common.actions.start_now'),
      icon: loginIcon,
      variant: loginVariant,
      className: loginClasses,
      redirect
    };
    return <AuthButton {...loginProps} />;
  }
  if (!session && !fallback && emptyState) {
    return <div className={cn(wrapperClasses)}>
        <Empty login icon="Lock" className={cn(className)} message={t('navigation.login.continue')} />
      </div>;
  }
  if (!session || plans && plans.length > 0 && !plans.includes(user.plan?.name)) {
    return fallback ? fallback : null;
  }
  if (verify) {
    return <AuthVerify wrapperClasses={wrapperClasses}>
        {children}
      </AuthVerify>;
  }
  return <>{children}</>;
}