import { useInfiniteQuery } from '@tanstack/react-query';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { matchfyApi } from '@/api';
import { CuratorPlaylistCard } from '@/components/Curator';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { CURATOR_PLAYLISTS_PER_PAGE } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const CuratorPlaylistGrid = ({
  className,
  userId
}) => {
  const {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: ['curatorPlaylists', userId],
    queryFn: async params => {
      const res = await matchfyApi.getCuratorPlaylists({
        ...params,
        limit: CURATOR_PLAYLISTS_PER_PAGE,
        userId
      });
      return res;
    },
    getNextPageParam: lastPage => {
      if (!lastPage?.nextPage) return;
      return lastPage.nextPage;
    },
    enabled: !!userId
  });
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);
  const t = useTranslations();
  const classes = cn({
    grid: true,
    'grid-cols-1': true,
    'gap-4': true,
    'sm:grid-cols-2': true,
    'md:grid-cols-4': true,
    'lg:grid-cols-5': true,
    [className]: className
  });
  const playlists = data?.pages?.map(page => page.data).flat() || [];
  return <>
      <div className={classes}>
        {playlists && playlists.length > 0 && playlists.map(playlist => <CuratorPlaylistCard key={nanoid()} playlist={playlist} />)}
      </div>

      {!isLoading && playlists && playlists.length === 0 && <Empty message={t('empty_state.content.playlists.generic')} />}

      {(isLoading || isFetching) && <Spinner />}

      <div ref={ref} />
    </>;
};
export default CuratorPlaylistGrid;