export const MatchProgress = ({
  score
}) => {
  return <div className="card-match-progress" data-sentry-component="MatchProgress" data-sentry-source-file="MatchProgress.jsx">
      <div className="progress">
        <div className="progress-bar" style={{
        width: `${score}%`
      }} />
      </div>
    </div>;
};
export default MatchProgress;