import { NavCommandItem } from './NavCommandItem';
import { CommandEmpty, CommandGroup, CommandSeparator } from '@/components/ui/Command/Command';
import Empty from '@/components/ui/Empty';
import { useTranslations } from '@/hooks';
export const SearchNavigationTab = ({
  items,
  setOpen
}) => {
  const t = useTranslations();
  return <>
      {items.map((group, index) => {
      if (group?.groupTitle) {
        return <>
              <CommandGroup key={group.groupId} heading={t(group.groupTitle)}>
                {group.items.map(item => <NavCommandItem key={item.id} item={item} setOpen={setOpen} />)}
              </CommandGroup>
              <CommandSeparator />
            </>;
      }
      if (group?.length > 0) {
        return <>
              <CommandGroup key={`group-${index}`}>
                {group.map(item => <NavCommandItem key={item.id} item={item} setOpen={setOpen} />)}
              </CommandGroup>
              <CommandSeparator />
            </>;
      }
    })}

      <CommandEmpty key="navigation-empty" data-sentry-element="CommandEmpty" data-sentry-source-file="SearchNavigationTab.jsx">
        <Empty ghost icon="Search" message={t('search.no_results')} data-sentry-element="Empty" data-sentry-source-file="SearchNavigationTab.jsx" />
      </CommandEmpty>
    </>;
};
export default SearchNavigationTab;