import { useTranslations } from 'next-intl';
import { ROUTES, generateRoute } from '@/data';
import { Link } from '@/i18n/routing';
function CuratorPageButton({
  isTrackInPlaylist,
  trackId
}) {
  const t = useTranslations();
  const activityPage = ROUTES.SUBMISSIONS;
  const activityTrackPage = generateRoute(ROUTES.SUBMISSIONS_DETAIL, {
    id: trackId
  });
  const activityTrackDetail = trackId ? activityTrackPage.replace(':trackId', trackId) : activityPage;
  return <div data-sentry-component="CuratorPageButton" data-sentry-source-file="CuratorPageButton.jsx">
      <p>
        {isTrackInPlaylist ? <span className="font-medium">
            {t('match.spotify.submit.states.already_pushed')}
          </span> : <span>
            {t('match.spotify.submit.states.track_added')}
          </span>}
      </p>

      <Link href={activityTrackDetail} className="mt-3 button button-primary" data-sentry-element="Link" data-sentry-source-file="CuratorPageButton.jsx">
        {t('match.spotify.submit.queue')}
      </Link>
    </div>;
}
export default CuratorPageButton;