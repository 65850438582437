'use client';

import { ResponsivePie } from '@nivo/pie';
import { createColorScale } from './helpers';
export const PieChart = ({
  chartData,
  primaryColor = '#5e4af9',
  arcLabelsTextColor = 'transparent'
}) => {
  const chartColor = primaryColor;
  const colorScale = createColorScale(chartColor, 10);
  return <div className="chart-pie" data-sentry-component="PieChart" data-sentry-source-file="PieChart.jsx">
      <ResponsivePie data={chartData} margin={{
      top: 80,
      right: 80,
      bottom: 80,
      left: 80
    }} innerRadius={0.7} padAngle={1} cornerRadius={4} activeOuterRadiusOffset={8} borderWidth={1} arcLinkLabelsSkipAngle={5} enableArcLinkLabels={true} arcLinkLabel={'id'} arcLinkLabelsThickness={2} arcLinkLabelsColor={{
      from: 'color'
    }} arcLabelsTextColor={arcLabelsTextColor} arcLabelsSkipAngle={2} colors={colorScale} animate={true} theme={{
      labels: {
        text: {
          fontSize: 16,
          fontWeight: 600
        }
      }
    }} tooltip={({
      datum: {
        data
      }
    }) => {
      if (!data) return;
      return <div className="chart-tooltip">
              <div className="px-3 py-2 bg-popover">
                <span>
                  <b>{data.label}</b>: {data.value}
                </span>
              </div>
            </div>;
    }} data-sentry-element="ResponsivePie" data-sentry-source-file="PieChart.jsx" />
    </div>;
};
export default PieChart;