import Badge from '@/components/ui/Badge';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
function TrackPopularityBadge({
  popularity,
  className
}) {
  const t = useTranslations();
  const classes = cn({
    [className]: className
  });
  return <Badge variant="light" label={t('common.metrics.popularity')} value={`${parseInt(popularity) > 0 ? popularity : '0'}%`} icon="Star" className={classes} data-sentry-element="Badge" data-sentry-component="TrackPopularityBadge" data-sentry-source-file="TrackPopularityBadge.jsx" />;
}
export default TrackPopularityBadge;