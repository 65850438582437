import Button from '@/components/ui/Button';
import Empty from '@/components/ui/Empty';
import Icon from '@/components/ui/Icon';
import { useTranslations, useSession } from '@/hooks';
import { cn } from '@/utils';
function Paywall({
  icon,
  message,
  plans,
  className,
  btnProps,
  children,
  ...props
}) {
  const t = useTranslations();
  const classes = cn({
    paywall: true,
    [className]: className
  });
  const {
    data: session
  } = useSession();
  const user = session?.user || {};
  if (plans && !session) {
    return <Empty login icon="Lock" className={classes} message={t('navigation.login.continue')} {...props} />;
  }
  if (plans && plans.includes(user?.plan?.name)) {
    return <>{children}</>;
  }
  return <div className={classes} data-sentry-component="Paywall" data-sentry-source-file="Paywall.jsx">
      {icon && <Icon name={icon} size="xl" />}
      {message && <p>{message}</p>}
      {children && !plans && <>{children}</>}

      {btnProps && <Button variant={btnProps?.variant || 'primary'} href={btnProps?.href} label={btnProps?.label} onClick={btnProps?.onClick || undefined} />}
    </div>;
}
export default Paywall;