'use client';

import { useEffect, useState } from 'react';
import { matchfyApi } from '@/api';
import Info from '@/components/ui/Info';
import { getPlatformUrl, getUsername } from '@/utils';
export function CuratorContacts({
  contacts: initialContacts,
  curatorId
}) {
  const [contacts, setContacts] = useState(initialContacts);
  const fetchCuratorContacts = async () => {
    const res = await matchfyApi.getCurator(curatorId);
    setContacts(res?.contacts);
  };
  useEffect(() => {
    if (curatorId && !contacts) {
      fetchCuratorContacts();
    }
  }, [curatorId, initialContacts]);
  return <>
      {contacts && contacts?.email && <Info icon="Mail" label="Email" value={<a href={`mailto:${contacts.email}`} target="_blank" rel="noopener noreferrer">
              {contacts.email}
            </a>} />}

      {contacts && contacts?.phone && <Info icon="Phone" label={t('field.phone.label')} value={<a href={`tel:${contacts.phone}`} target="_blank" rel="noopener noreferrer">
              {contacts.phone}
            </a>} />}

      {contacts && contacts.tiktok && <Info icon="TikTok" iconType="brand" label="TikTok" value={<a href={getPlatformUrl('tiktok', contacts?.tiktok)} target="_blank" rel="noopener noreferrer">
              {contacts?.tiktok}
            </a>} />}

      {contacts && contacts?.facebook && <Info icon="Facebook" iconType="brand" label="Facebook" value={<a href={getPlatformUrl('facebook', contacts?.facebook)} target="_blank" rel="noopener noreferrer">
              {contacts?.facebook}
            </a>} />}

      {contacts && contacts?.instagram && <Info icon="Instagram" iconType="brand" label="Instagram" value={<a href={getPlatformUrl('instagram', contacts?.instagram)} target="_blank" rel="noopener noreferrer">
              {getUsername(contacts?.instagram)}
            </a>} />}

      {contacts && contacts?.linkedin && <Info icon="LinkedIn" iconType="brand" label="LinkedIn" value={<a href={getPlatformUrl('linkedin', contacts?.linkedin)} target="_blank" rel="noopener noreferrer">
              {getUsername(contacts?.linkedin)}
            </a>} />}

      {contacts && contacts?.twitter && <Info icon="Twitter" iconType="brand" label="Twitter" value={<a href={getPlatformUrl('twitter', contacts?.twitter)} target="_blank" rel="noopener noreferrer">
              {getUsername(contacts?.twitter)}
            </a>} />}

      {contacts && contacts?.youtube && <Info icon="Youtube" iconType="brand" label="YouTube" value={<a href={getPlatformUrl('youtube', contacts?.youtube)} target="_blank" rel="noopener noreferrer">
              {getUsername(contacts?.youtube)}
            </a>} />}

      {contacts && contacts?.website && <Info icon="Link" label="Website" value={<a href={getPlatformUrl('website', contacts?.website)} target="_blank" rel="noopener noreferrer" className="truncate">
              {contacts?.website}
            </a>} />}
    </>;
}
export default CuratorContacts;