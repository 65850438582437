import { Command as CommandPrimitive } from 'cmdk';
import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/Dialog/Dialog';
import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
const Command = React.forwardRef(({
  className,
  ...props
}, ref) => <CommandPrimitive ref={ref} className={cn('flex h-full w-full flex-col overflow-hidden rounded-md bg-popover', className)} {...props} />);
Command.displayName = CommandPrimitive.displayName;
const CommandDialog = ({
  children,
  title,
  filter,
  ...props
}) => {
  return <Dialog {...props} data-sentry-element="Dialog" data-sentry-component="CommandDialog" data-sentry-source-file="Command.jsx">
      <DialogContent className="p-0 overflow-hidden shadow-lg" data-sentry-element="DialogContent" data-sentry-source-file="Command.jsx">
        {title && <DialogTitle className="hidden">
            {title}
          </DialogTitle>}

        <Command filter={filter} className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5" data-sentry-element="Command" data-sentry-source-file="Command.jsx">
          {children}
        </Command>
      </DialogContent>
    </Dialog>;
};
const CommandInput = React.forwardRef(({
  className,
  ...props
}, ref) => <div className="flex items-center px-3 border-b command-input">
    <Icon name="Search" className="opacity-50 shrink-0" />
    <CommandPrimitive.Input ref={ref} className={cn('flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50', className)} {...props} />
  </div>);
CommandInput.displayName = CommandPrimitive.Input.displayName;
const CommandList = React.forwardRef(({
  className,
  ...props
}, ref) => <CommandPrimitive.List ref={ref} className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)} {...props} />);
CommandList.displayName = CommandPrimitive.List.displayName;
const CommandEmpty = React.forwardRef((props, ref) => <CommandPrimitive.Empty ref={ref} className="py-6 text-sm text-center" {...props} />);
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
const CommandGroup = React.forwardRef(({
  className,
  ...props
}, ref) => <CommandPrimitive.Group ref={ref} className={cn('overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground', className)} {...props} />);
CommandGroup.displayName = CommandPrimitive.Group.displayName;
const CommandSeparator = React.forwardRef(({
  className,
  ...props
}, ref) => <CommandPrimitive.Separator ref={ref} className={cn('-mx-1 h-px bg-border', className)} {...props} />);
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
const CommandItem = React.forwardRef(({
  className,
  ...props
}, ref) => <CommandPrimitive.Item ref={ref} className={cn('command-item relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none', className)} {...props} />);
CommandItem.displayName = CommandPrimitive.Item.displayName;
const CommandShortcut = ({
  className,
  ...props
}) => {
  return <span className={cn('ml-auto text-xs tracking-widest text-muted-foreground', className)} {...props} data-sentry-component="CommandShortcut" data-sentry-source-file="Command.jsx" />;
};
CommandShortcut.displayName = 'CommandShortcut';
export { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, CommandShortcut };