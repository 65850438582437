import { TabLinks, useTabs } from '@/components/ui/Tabs';
import { MATCH_CHANNELS } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export function MatchSwitch({
  initial = 'spotify',
  className
}) {
  const t = useTranslations();
  const matchTabs = MATCH_CHANNELS.map(channel => ({
    ...channel,
    id: channel.path.split('/').pop(),
    label: t(channel.title)
  }));
  const tabsData = {
    tabs: matchTabs,
    initialTabId: initial
  };
  const tabs = useTabs(tabsData);
  const classes = cn({
    'match-switch': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="MatchSwitch" data-sentry-source-file="MatchSwitch.jsx">
      <TabLinks inline variant="toggle" showIndicator={false} {...tabs.tabProps} data-sentry-element="TabLinks" data-sentry-source-file="MatchSwitch.jsx" />
    </div>;
}
export default MatchSwitch;