'use client';

import { useQuery } from '@tanstack/react-query';
import { memo, useEffect, useState } from 'react';
import { VerifiedIcon } from '@/components/ui/Icon/VerifiedIcon';
import { cn } from '@/utils';
function Image({
  src,
  size,
  label,
  star,
  name,
  placeholder,
  hideStarBadge = false,
  imageClasses,
  className,
  fetchFn
}) {
  const [imageLoaded, setImageLoaded] = useState(true);
  const [image, setImage] = useState(src);
  const {
    data,
    isSuccess
  } = useQuery({
    queryKey: ['playlistImage', src],
    queryFn: fetchFn,
    enabled: !image || image == ''
  });
  useEffect(() => {
    setImage(src);
  }, [src]);
  useEffect(() => {
    if (isSuccess) {
      setImage(data?.url);
      setImageLoaded(true);
    }
  }, [isSuccess]);
  const handleError = () => {
    setImageLoaded(false);
    setImage('');
  };
  const classes = cn({
    [size]: size,
    'border': !imageLoaded,
    [className]: className
  });
  const imageCn = cn({
    [imageClasses]: imageClasses
  });
  return <figure className={classes} data-sentry-component="Image" data-sentry-source-file="Image.jsx">
      {src && imageLoaded && <img src={image} alt={label} aria-label={label} onError={handleError} className={imageCn} />}

      {!src && name && <span className="uppercase">
          {`${name.charAt(0)}${name.charAt(1)}`}
        </span>}

      {(!src && !name || !imageLoaded) && (typeof placeholder === 'function' ? placeholder({
      size
    }) : placeholder)}

      {star && !hideStarBadge && <span className="verified">
          <VerifiedIcon />
        </span>}
    </figure>;
}
export default memo(Image);