import { ProgressBar } from '@/components/ui/Progress';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
function ArtistPopularityBar({
  popularity,
  className
}) {
  const t = useTranslations();
  const classes = cn({
    'artist-popularity': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="ArtistPopularityBar" data-sentry-source-file="ArtistPopularityBar.jsx">
      <ProgressBar variant="green" valueLabel={`${popularity}%`} label={t('artist.popularity.label')} value={popularity} data-sentry-element="ProgressBar" data-sentry-source-file="ArtistPopularityBar.jsx" />
    </div>;
}
export default ArtistPopularityBar;