'use client';

import { useEffect, useState } from 'react';
import ConfirmationPopup from '@/components/ConfirmationPopup';
import { SearchSelect } from '@/components/Search';
import Button from '@/components/ui/Button';
import { PLANS } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useArtistStore } from '@/store';
import { cn } from '@/utils';
function ArtistForm({
  btnVariant = 'primary',
  className,
  onLimitReached,
  onArtistSelect,
  searchInputClasses
}) {
  const [artist, setArtist] = useState();
  const [artistId, setArtistId] = useState();
  const [clearArtist, setClearArtist] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const t = useTranslations();
  const {
    addArtist,
    isLoading,
    limitReached,
    resetFeedback
  } = useArtistStore();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  useEffect(() => {
    if (onLimitReached) {
      onLimitReached(limitReached);
    }
  }, [limitReached]);
  const onChange = value => {
    setClearArtist(false);
    setArtistId(value);
  };
  const handleConfirmation = async () => {
    try {
      await addArtist(artist);
      setClearArtist(true);
      setIsConfirmationOpen(false);
      resetFeedback();
      if (onArtistSelect) {
        onArtistSelect(artist);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async () => {
    if (!artistId || !artist) return;
    if (user.plan.name === PLANS.AGENCY) {
      await handleConfirmation();
      return;
    } else {
      setIsConfirmationOpen(true);
    }
  };
  const classes = cn({
    'flex flex-col md:flex-row items-start gap-2': true,
    [className]: className
  });
  const searchInputCn = cn({
    [searchInputClasses]: searchInputClasses
  });
  return <div className={classes} data-sentry-component="ArtistForm" data-sentry-source-file="ArtistForm.jsx">
      <SearchSelect id="artist-field" className={searchInputCn} name="name" entity="artist" clear={clearArtist} onChange={onChange} onDataChange={setArtist} isDisabled={isLoading} data-sentry-element="SearchSelect" data-sentry-source-file="ArtistForm.jsx" />

      <Button loading={isLoading} disabled={isLoading} icon="Check" variant={btnVariant} onClick={handleSubmit} label={t('artist.form.actions.confirm_artist')} className="h-[46px] w-full md:w-auto md:mt-8" data-sentry-element="Button" data-sentry-source-file="ArtistForm.jsx" />

      {user.plan.name !== PLANS.AGENCY && <ConfirmationPopup open={isConfirmationOpen} title={t('artist.form.actions.confirm_artist')} onConfirm={handleConfirmation} loading={isLoading} onReject={() => setIsConfirmationOpen(false)}>
          {artist && <div className="flex items-center justify-start gap-3 mb-4">
              <img src={artist?.images[0]?.url} alt={artist?.name} className="w-12 h-12 rounded-full" />
              <h5 className="m-0">{artist?.name}</h5>
            </div>}

          <p>{t('feedback.info.artist_select')}</p>
        </ConfirmationPopup>}
    </div>;
}
export default ArtistForm;