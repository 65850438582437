"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";
import Spinner from '@/components/ui/Spinner';
import { cn } from "@/utils";
export const Switch = React.forwardRef(({
  className,
  loading,
  ...props
}, ref) => <SwitchPrimitives.Root className={cn("switch peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50", loading && 'opacity-50', className)} {...props} ref={ref}>
    <SwitchPrimitives.Thumb className={cn("inline-flex items-center justify-center switch-thumb pointer-events-none h-5 w-5 rounded-full shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0")}>
      {loading && <Spinner inline />}
    </SwitchPrimitives.Thumb>
  </SwitchPrimitives.Root>);
Switch.displayName = SwitchPrimitives.Root.displayName;
export default Switch;