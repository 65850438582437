'use client';

import { useQueryClient } from '@tanstack/react-query';
import { Client as ConversationsClient } from '@twilio/conversations';
import { useEffect, useState } from 'react';
import { matchfyApi } from '@/api';
import { ChatHeader, ChatInput, ChatMessages } from '@/components/Chat';
import { CHAT_MESSAGES_PER_PAGE } from '@/data';
import { QUERIES } from '@/data/queries';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { useChatStore } from '@/store';
import { cn } from '@/utils';
export const Chat = ({
  activeSid,
  mobile,
  user,
  className
}) => {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [identity] = useState(user.spotifyId);
  const [totalMessages, setTotalMessages] = useState(0);
  const {
    chats,
    getChatMessages,
    setChat,
    setChatMessages,
    setLoadedChats,
    updateChatTimestamp,
    updateChatUnread,
    syncChats,
    success,
    error,
    resetFeedback
  } = useChatStore();
  const queryClient = useQueryClient();
  const t = useTranslations();
  const fetchMessages = async () => {
    if (!chats[activeSid]) return;
    const currentMessages = getChatMessages();
    const total = await chats[activeSid].getMessagesCount();
    setTotalMessages(total);
    if (currentMessages && currentMessages.length >= total) {
      setHasMore(false);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const anchor = currentMessages && currentMessages.length > 0 ? currentMessages[0].index - 1 : undefined;
    const messages = await chats[activeSid].getMessages(CHAT_MESSAGES_PER_PAGE, anchor, 'backwards');
    if (messages.items.length > 0) {
      messages.items[0].blockStart = true;
      messages.items[messages.items.length - 1].blockEnd = true;
    }
    setChatMessages(activeSid, messages.items, 'prepend');
    setLoadedChats(activeSid);
    await chats[activeSid].setAllMessagesRead();
    queryClient.invalidateQueries(QUERIES.CHAT.COUNT);
    setIsLoading(false);
  };
  useEffect(() => {
    const createToken = async () => {
      const {
        token
      } = await matchfyApi.createToken(identity);
      setToken(token);
    };
    createToken();
  }, [identity]);
  useEffect(() => {
    setHasMore(true);
    if (token) {
      const twilioClient = new ConversationsClient(token);
      twilioClient.on('stateChanged', async state => {
        if (state === 'initialized') {
          if (activeSid) {
            const chat = await twilioClient.getConversationBySid(activeSid);
            setChat(activeSid, chat);
            updateChatUnread(activeSid);
            syncChats();
          }
        }
      });
    }
  }, [activeSid, token]);
  useEffect(() => {
    if (chats[activeSid]) {
      fetchMessages();
      const handleMessageAdded = message => {
        setChatMessages(activeSid, [message], 'append');
        updateChatTimestamp(activeSid);
      };
      const handleChatUpdated = async () => {
        syncChats();
      };
      chats[activeSid].on('messageAdded', handleMessageAdded);
      chats[activeSid].on('updated', handleChatUpdated);
      return () => {
        if (chats[activeSid]) {
          chats[activeSid].off('messageAdded', handleMessageAdded);
          chats[activeSid].off('updated', handleChatUpdated);
        }
      };
    }
  }, [activeSid, chats]);
  useEffect(() => {
    if (error && !isLoading) {
      toast('error', t(error));
    } else if (!error && !isLoading && success) {
      toast('success', t(success));
    }
    resetFeedback();
  }, [success, error]);
  const handleLoadMore = async () => {
    if (isLoading || !hasMore) return;
    await fetchMessages();
  };
  const classes = cn({
    chat: true,
    [className]: className
  });
  if (!activeSid) return <div className="h-full min-h-[50vh]"></div>;
  return <div className={classes} data-sentry-component="Chat" data-sentry-source-file="Chat.jsx">
      <ChatHeader mobile={mobile} data-sentry-element="ChatHeader" data-sentry-source-file="Chat.jsx" />

      <ChatMessages onLoadMore={handleLoadMore} isLoading={isLoading} messages={getChatMessages()} mobile={mobile} total={totalMessages} user={user} data-sentry-element="ChatMessages" data-sentry-source-file="Chat.jsx" />

      <ChatInput mobile={mobile} data-sentry-element="ChatInput" data-sentry-source-file="Chat.jsx" />
    </div>;
};
export default Chat;