import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import Thumbnail from '@/components/ui/Thumbnail';
import { useMatchStore } from '@/store';
import { cn } from '@/utils';
function MatchTrack({
  className
}) {
  const {
    track
  } = useMatchStore();
  const thumbnailEntity = useMemo(() => {
    if (isEmpty(track)) {
      return {};
    }
    return {
      url: track.external_urls.spotify,
      picture: track.album.images[0].url,
      title: track.name,
      description: track.album.name,
      owners: track.artists
    };
  }, [track]);
  const classes = cn(className);
  return <div className={classes} data-sentry-component="MatchTrack" data-sentry-source-file="MatchTrack.jsx">
      <Thumbnail entity={thumbnailEntity} data-sentry-element="Thumbnail" data-sentry-source-file="MatchTrack.jsx" />
    </div>;
}
export default MatchTrack;