import { TrackPlayer } from '@/components/Track';
import { buildSpotifyArtistUrl, buildSpotifyTrackUrl, cn } from '@/utils';
export const TrackItem = ({
  id,
  name,
  artist,
  image,
  size,
  previewUrl,
  disablePlayer,
  className
}) => {
  const trackClasses = cn({
    'track-item': true,
    [size]: size,
    [className]: className
  });
  return <div className={trackClasses} data-sentry-component="TrackItem" data-sentry-source-file="TrackItem.jsx">
      <TrackPlayer cover={image} audioSrc={previewUrl} disablePlayer={disablePlayer} data-sentry-element="TrackPlayer" data-sentry-source-file="TrackItem.jsx" />

      <div className="track-info">
        <a href={buildSpotifyTrackUrl(id)} target="_blank" rel="noopener noreferrer">
          {name}
        </a>

        {artist && <a href={buildSpotifyArtistUrl(artist?.id)} target="_blank" className="text-xs text-muted-foreground" rel="noopener noreferrer">
            {artist?.name}
          </a>}
      </div>
    </div>;
};
export const trackImg = track => {
  if (track?.album?.images && Array.isArray(track.album.images)) {
    const image = track.album.images.find(img => img && img.height === 64);
    return image || track.album.images[0] || null;
  }
  return null;
};
export default TrackItem;