import { components } from 'react-select';
import { cn } from '@/utils';
export const CustomImageSingleValue = props => {
  const value = props.data.value;
  const selectedOption = props.options.find(option => option.value === value);
  const imageClasses = cn({
    [props?.selectProps?.imageClasses]: props?.selectProps?.imageClasses
  });
  return <components.SingleValue {...props} data-sentry-element="unknown" data-sentry-component="CustomImageSingleValue" data-sentry-source-file="SelectComponents.jsx">
      <div className="flex items-center gap-2">
        {selectedOption?.image && <img src={selectedOption.image} alt={selectedOption.label} className={imageClasses} />}
        <span>{selectedOption?.label}</span>
      </div>
    </components.SingleValue>;
};
export const CustomImageOption = props => {
  const value = props.data.value;
  const selectedOption = props.options.find(option => option.value === value);
  const imageClasses = cn({
    [props?.selectProps?.imageClasses]: props?.selectProps?.imageClasses
  });
  return <components.Option {...props} data-sentry-element="unknown" data-sentry-component="CustomImageOption" data-sentry-source-file="SelectComponents.jsx">
      <div className="flex items-center gap-2">
        {selectedOption?.image && <img src={selectedOption.image} alt={selectedOption.label} className={imageClasses} />}
        <span>{selectedOption?.label}</span>
      </div>
    </components.Option>;
};