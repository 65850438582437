'use client';

import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@/context/ThemeProvider';
export const Chart = ({
  id = 'chart-dataset',
  data,
  showBottomAxis,
  showLeftAxis,
  showArea = false,
  isInteractive = false,
  color = 'primary',
  yScale = {}
}) => {
  const [renderChart, setRenderChart] = useState(false);
  const [chartData, setChartData] = useState({
    id,
    data
  });
  useEffect(() => {
    if (!Array.isArray(data)) {
      const _data = Object.keys(data).map(key => {
        return {
          x: key,
          y: data[key]
        };
      });
      setChartData({
        id,
        data: _data
      });
    }
  }, []);
  useEffect(() => {
    if (Array.isArray(chartData.data)) {
      const canRender = chartData.data.every(e => e.y !== null || e.y !== undefined) && !chartData.data.every(e => e.y === 0);
      setRenderChart(canRender);
    }
  }, [chartData]);
  const {
    getCurrentTheme
  } = useContext(ThemeContext);
  const theme = getCurrentTheme();
  const colors = Array.isArray(color) ? color : theme.palette[color];
  const axisBottomOptions = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -45,
    format: value => {
      return new Date(value).toLocaleDateString('it-IT', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  };
  const axisLeftOptions = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0
  };
  return <div className="chart-lines" data-sentry-component="Chart" data-sentry-source-file="Chart.jsx">
      {renderChart && <ResponsiveLine data={[chartData]} margin={{
      top: 30,
      right: 0,
      bottom: showBottomAxis ? 60 : 0,
      left: showLeftAxis ? 60 : 0
    }} yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
      reverse: false,
      ...yScale
    }} axisTop={null} axisRight={null} axisBottom={showBottomAxis ? axisBottomOptions : null} axisLeft={showLeftAxis ? axisLeftOptions : null} curve="linear" enablePoints={false} pointSize={1} pointColor={{
      theme: 'background'
    }} pointBorderWidth={0} colors={colors} pointBorderColor={{
      from: 'serieColor'
    }} pointLabelYOffset={-12} useMesh={true} lineWidth={2} enableArea={showArea} areaBlendMode="overlay" enableCrosshair={true} enableSlices={false} gridXValues={[]} gridYValues={[]} defs={[
    // using helpers
    // will inherit colors from current element
    linearGradientDef('gradientA', [{
      offset: 0,
      color: 'inherit'
    }, {
      offset: 100,
      color: 'inherit',
      opacity: 0
    }])]} fill={[{
      match: '*',
      id: 'gradientA'
    }]} isInteractive={isInteractive} tooltip={({
      point
    }) => {
      return <div className="chart-tooltip">
                <p className="p-1 bg-popover">
                  {point.data.x_original ? dayjs(point.data.x_original).format('D MMM YYYY') : dayjs(point.data.x).format('D MMM YYYY')}
                  : <strong>{point.data.y.toLocaleString()}</strong>
                </p>
              </div>;
    }} />}
    </div>;
};
export default Chart;