'use client';

import { memo } from 'react';
import Image from '@/components/ui/Image';
import { Placeholder } from '@/components/ui/Placeholder';
import { cn } from '@/utils';
function Avatar({
  src,
  size,
  label,
  star = false,
  name,
  icon = 'UserRound',
  hideStarBadge = false,
  className
}) {
  const classes = cn({
    avatar: true,
    'avatar-placeholder': !src && !name,
    'avatar-name': !src && name,
    'avatar-star': star,
    [className]: className
  });
  return <Image src={src} size={size} label={label} alt={label} star={star} name={name} icon={icon} hideStarBadge={hideStarBadge} className={classes} placeholder={<Placeholder size={size} icon={icon} />} data-sentry-element="Image" data-sentry-component="Avatar" data-sentry-source-file="Avatar.jsx" />;
}
export default memo(Avatar);