'use client';

import dayjs from 'dayjs';
import { memo, useMemo, useState } from 'react';
import AutoAcceptPopup from './AutoAcceptPopup';
import PlaylistCover from './PlaylistCover';
import PlaylistPromote from './PlaylistPromote';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/Tooltip/Tooltip';
import { PlaylistSettings } from '@/components/Playlist';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { Switch } from '@/components/ui/Switch';
import { generateRoute, ROUTES, AUTO_ACCEPT_MIN_MATCH_RATE } from '@/data';
import { useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { usePlaylistStore } from '@/store';
import { formatNumber } from '@/utils';
const isPlaylistFeatured = playlist => {
  if (!playlist?.featuredEndsAt) return false;
  const endDate = dayjs(playlist.featuredEndsAt);
  return endDate.isAfter(dayjs());
};
export const PlaylistTableItem = ({
  index,
  isUserVerified,
  loadingEnabled,
  loadingWatch,
  playlist,
  toggleVerification,
  toggleWatch,
  updatePlaylistData
}) => {
  const router = useRouter();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const [isAutoSubmitModalOpen, setIsAutoSubmitModalOpen] = useState(false);
  const {
    setPlaylist,
    resetTracks
  } = usePlaylistStore();
  const isFeatured = useMemo(() => isPlaylistFeatured(playlist), [playlist]);
  const t = useTranslations();
  const handleSettingsClose = () => {
    setIsSettingsOpen(false);
  };
  const onSettingsClick = async () => {
    setPlaylist(playlist.playlistId, playlist);
    resetTracks(playlist.playlistId);
    setIsSettingsOpen(true);
  };
  const onAutoSubmitClick = () => {
    setPlaylist(playlist.playlistId, playlist);
    setIsAutoSubmitModalOpen(true);
  };
  const onEnabledClick = async value => {
    setPlaylist(playlist.playlistId, playlist);
    await toggleVerification(value);
  };
  const onWatchClick = async () => {
    setPlaylist(playlist.playlistId, playlist);
    await toggleWatch();
  };
  const onAnalysisClick = () => {
    setPlaylist(playlist.playlistId, playlist);
    router.push(generateRoute(ROUTES.PLAYLIST_CHECK_DETAIL, {
      id: playlist.playlistId
    }));
  };
  return <tr data-sentry-component="PlaylistTableItem" data-sentry-source-file="PlaylistTableItem.jsx">
      <td>
        <div className="flex items-start gap-3">
          <PlaylistCover image={playlist?.images?.[0]?.url} name={playlist?.name} className="flex-shrink-0" data-sentry-element="PlaylistCover" data-sentry-source-file="PlaylistTableItem.jsx" />

          <div className="flex flex-col items-start">
            <span className="font-medium cursor-pointer">
              {index}
              {'. '}
              {playlist.playlistName}
            </span>

            <div className="flex flex-wrap items-center gap-1 mt-1">
              <Badge size="sm" variant="light" label={t('common.metrics.tracks')} value={formatNumber(playlist?.numTracks)} data-sentry-element="Badge" data-sentry-source-file="PlaylistTableItem.jsx" />

              <Badge size="sm" variant="light" label={t('common.metrics.followers')} value={formatNumber(playlist?.numFollowers ?? 0)} data-sentry-element="Badge" data-sentry-source-file="PlaylistTableItem.jsx" />
            </div>
          </div>
        </div>
      </td>

      <td>
        <Button small variant={playlist.autoAcceptRate < 100 ? 'blue-light' : 'inverse-light'} label={playlist.autoAcceptRate < 100 ? `${playlist.autoAcceptRate.toFixed(0)}%` : 'Off'} icon={playlist.autoAcceptRate < 100 ? 'Sparkles' : 'ToggleLeft'} onClick={onAutoSubmitClick} name={playlist.id} data-sentry-element="Button" data-sentry-source-file="PlaylistTableItem.jsx" />
      </td>

      <td className="playlist-verify">
        <Switch checked={playlist.enabled} loading={loadingEnabled === playlist.playlistId} disabled={!isUserVerified} onCheckedChange={onEnabledClick} name={playlist.id} data-sentry-element="Switch" data-sentry-source-file="PlaylistTableItem.jsx" />
      </td>

      <td className="playlist-watch">
        {!isUserVerified || !playlist.public ? <Tooltip>
              <TooltipTrigger asChild>
                <Switch loading={loadingWatch === playlist.playlistId} checked={false} disabled={true} onCheckedChange={onWatchClick} name={playlist.id} />
              </TooltipTrigger>
              <TooltipContent className="max-w-[280px]">
                <span>{t(!isUserVerified ? 'playlist.table.columns.watch.tooltip_verified' : 'playlist.table.columns.watch.tooltip_public')}</span>
              </TooltipContent>
            </Tooltip> : <Switch loading={loadingWatch === playlist.playlistId} checked={playlist.watch} onCheckedChange={onWatchClick} name={playlist.id} />}
      </td>

      <td className="playlist-requests">
        <Button small icon="Inbox" variant={playlist?.submission > 0 ? 'primary-light' : 'light'} label={`${playlist?.submission ?? 0}`} onClick={() => {
        setPlaylist(playlist.playlistId, playlist);
        router.push(generateRoute(ROUTES.PLAYLIST_DETAIL, {
          id: playlist.playlistId
        }));
      }} data-sentry-element="Button" data-sentry-source-file="PlaylistTableItem.jsx" />
      </td>

      <td>
        <div className="table-actions">
        {isFeatured ? <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="green-light" disabled={true} onClick={() => setIsPromoteModalOpen(true)} icon="Rocket" />
              </TooltipTrigger>
              <TooltipContent className="max-w-[320px] py-3" side="left">
                <div className="flex flex-col gap-2">
                  <span>{t('playlist.promote.tooltip.title')}</span>
                  <span>{t('playlist.promote.tooltip.until')}</span>
                  <Badge label={dayjs(playlist.featuredEndsAt).format('DD/MM/YYYY')} variant="green-light" />
                </div>
              </TooltipContent>
            </Tooltip> : <Button variant="green-light" onClick={() => setIsPromoteModalOpen(true)} icon="Rocket" />}
          <Button variant="blue-light" onClick={onAnalysisClick} icon="ChartLine" data-sentry-element="Button" data-sentry-source-file="PlaylistTableItem.jsx" />

          <Button variant="light" icon="Settings" onClick={onSettingsClick} data-sentry-element="Button" data-sentry-source-file="PlaylistTableItem.jsx" />
        </div>
      </td>

      <Dialog open={isSettingsOpen} onOpenChange={handleSettingsClose} data-sentry-element="Dialog" data-sentry-source-file="PlaylistTableItem.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="PlaylistTableItem.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PlaylistTableItem.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PlaylistTableItem.jsx">
              {playlist.playlistName}

              {!playlist.enabled && <Badge size="sm" className="ml-3" variant="blue" icon="TriangleAlert" label={t('common.states.unverified')} />}

              {playlist.enabled && <Badge size="sm" className="ml-3" variant="green" icon="Check" label={t('playlist.table.columns.verified.label')} />}
            </DialogTitle>
          </DialogHeader>

          <PlaylistSettings closeModalFn={handleSettingsClose} data-sentry-element="PlaylistSettings" data-sentry-source-file="PlaylistTableItem.jsx" />
        </DialogContent>
      </Dialog>

      <AutoAcceptPopup defaultValue={playlist.autoAcceptRate < 100 ? playlist.autoAcceptRate : AUTO_ACCEPT_MIN_MATCH_RATE} updatePlaylistData={updatePlaylistData} open={isAutoSubmitModalOpen} onClose={() => setIsAutoSubmitModalOpen(false)} playlistId={playlist.playlistId} data-sentry-element="AutoAcceptPopup" data-sentry-source-file="PlaylistTableItem.jsx" />

      <PlaylistPromote isFeatured={isFeatured} open={isPromoteModalOpen} onClose={() => setIsPromoteModalOpen(false)} playlist={playlist} data-sentry-element="PlaylistPromote" data-sentry-source-file="PlaylistTableItem.jsx" />
    </tr>;
};
export default memo(PlaylistTableItem);