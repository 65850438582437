import { cn } from '@/utils';
export const NotificationBadge = ({
  count,
  limit = 99,
  hideCount = false
}) => {
  if (count === 0) {
    return null;
  }
  const classes = cn({
    'notification-badge': true,
    'hide-count': hideCount,
    'double-digit': count >= 10 && count < limit,
    'over-limit': count > limit
  });
  if (hideCount) {
    return <div className={classes} />;
  }
  return <div className={classes} data-sentry-component="NotificationBadge" data-sentry-source-file="NotificationBadge.jsx">{count > limit ? `${limit}+` : count}</div>;
};
export default NotificationBadge;