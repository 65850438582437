import dayjs from 'dayjs';
import React from 'react';
import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import { USER_TYPES } from '@/data';
import { useLocale, useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const ChatItem = React.forwardRef(({
  chat,
  onClick,
  active,
  className
}, ref) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const handleChatClick = () => {
    if (onClick) {
      onClick(chat);
    }
  };
  const classes = cn({
    'chat-list-item': true,
    active: active,
    [className]: className
  });
  return <div ref={ref} className={classes} onClick={handleChatClick}>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-3">
            {chat?.avatar ? <Avatar src={chat.avatar} star={chat.type === USER_TYPES.STAR} /> : <Avatar star={chat.type === USER_TYPES.STAR} />}

            <div className="flex flex-col items-start">
              <span className="font-medium">{chat?.friendlyName}</span>
              {chat?.dateUpdated && <small className="opacity-70">
                  {dayjs(chat?.dateUpdated).locale(locale).fromNow()}
                </small>}
            </div>
          </div>

          {chat?.unreadCount > 0 && <Badge size="sm" variant="primary" label={chat.unreadCount} />}
        </div>
      </div>;
});
export default ChatItem;