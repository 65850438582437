import { isEmpty } from 'lodash-es';
import { memo } from 'react';
import Icon from '@/components/ui/Icon';
import { Placeholder } from '@/components/ui/Placeholder';
import Spinner from '@/components/ui/Spinner';
import { useTranslations } from '@/hooks';
function Thumbnail({
  error,
  entity,
  isLoading,
  isShrink
}) {
  const t = useTranslations();
  const TrackOwners = () => {
    let trackOwners = [];
    if (!isEmpty(entity.owners)) {
      entity.owners.forEach(owner => {
        trackOwners.push(owner.name);
      });
      return <p className="flex items-start text-left gap-2 mt-[0.2rem] font-normal opacity-70">
          <Icon name="MicVocal" className="shrink-0 mt-[0.2rem]" />
          {trackOwners.join(', ').length > 128 ? `${trackOwners.join(', ').substring(0, 128)}...` : trackOwners.join(', ')}
        </p>;
    }
    return null;
  };
  return <div className="thumbnail-image" data-sentry-component="Thumbnail" data-sentry-source-file="Thumbnail.jsx">
      {isLoading && <Spinner />}

      {isEmpty(entity) && !isLoading && !error && <Placeholder size="xl" icon="Music" label={t('match.common.url_validation.empty')} />}

      {!isLoading && error && <Placeholder size="xl" icon="Music" label={t('match.common.url_validation.invalid')} />}

      {!isLoading && !isEmpty(entity) && !error && <a className="thumbnail-link" href={entity.url} target="_blank" rel="noopener noreferrer">
          <div className={`thumbnail-bg ${!isShrink ? 'thumbnail-bg-auto' : ''}`} style={{
        position: 'relative',
        backgroundImage: `url("${entity.picture}")`
      }}>
            <div className="overlay" />

            {entity.featured && <div className="badge badge-green">
                <Icon name="Star" />{' '}
                {t('match.spotify.filters.display.verified')}
              </div>}

            <div className="text-center thumbnail-inner">
              <h3 className="thumbnail-title">
                {entity.title.length > 64 ? `${entity.title.substring(0, 64)}...` : entity.title}
              </h3>

              {entity?.description && <p className="flex items-start gap-2 font-normal text-left opacity-70">
                  <Icon name="DiscAlbum" className="shrink-0 mt-[0.2rem]" />
                  {entity.description}
                </p>}

              <TrackOwners />
            </div>
          </div>
        </a>}
    </div>;
}
export default memo(Thumbnail);