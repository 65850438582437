'use client';

import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { TrackItem, trackImg } from './TrackItem';
import { spotifyApi } from '@/api';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { TRACKS_PER_PAGE } from '@/data';
import { useTranslations } from '@/hooks';
export const TrackDetails = ({
  tracks = [],
  playlistId
}) => {
  const [trackList, setTrackList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const t = useTranslations();
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  const fetchTracks = async () => {
    if (playlistId && hasMore) {
      setIsLoading(true);
      const offset = page * TRACKS_PER_PAGE;
      const trackData = await spotifyApi.getPlaylistTracksByPage(playlistId, TRACKS_PER_PAGE, offset);
      const newTracks = trackData?.map(item => item.track) || [];
      setTrackList(prev => [...prev, ...newTracks]);
      setHasMore(newTracks.length === TRACKS_PER_PAGE);
      setPage(prev => prev + 1);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (tracks.length > 0) {
      setTrackList(tracks);
      setHasMore(false);
      setIsLoading(false);
    } else if (playlistId) {
      setTrackList([]);
      setPage(0);
      setHasMore(true);
      fetchTracks();
    }
  }, [playlistId]);
  useEffect(() => {
    if (inView && !isLoading && hasMore) {
      fetchTracks();
    }
  }, [inView]);
  if (!trackList || trackList.length === 0 && !isLoading) {
    return <Empty icon="ListMusic" className="m-0" message={t('empty_state.content.tracks.search')} />;
  }
  return <>
      <div className="track-grid">
        {trackList.map((track, index) => {
        if (!track || !track?.id) return null;
        return <TrackItem key={`${track.id}${index}`} id={track.id} previewUrl={track?.preview_url} name={track?.name ? track.name.substring(0, 40) : ''} artist={{
          id: track?.artists?.[0]?.artistId,
          name: track?.artists?.[0]?.name
        }} image={trackImg(track) ? trackImg(track).url : ''} />;
      })}
      </div>

      {isLoading && <div className="flex justify-center w-full py-5">
          <Spinner inline />
        </div>}

      {!isLoading && <div ref={ref} className="w-full h-1" />}
    </>;
};
export default TrackDetails;