import Badge from '@/components/ui/Badge';
import { useTranslations } from '@/hooks';
function ArtistPopularityBadge({
  artist,
  className
}) {
  const t = useTranslations();
  return <div className={className} data-sentry-component="ArtistPopularityBadge" data-sentry-source-file="ArtistPopularityBadge.jsx">
      <Badge key={artist?.id} variant="light" label={artist?.name} image={artist?.images?.[0]?.url} value={artist?.popularity} data-sentry-element="Badge" data-sentry-source-file="ArtistPopularityBadge.jsx" />
    </div>;
}
export default ArtistPopularityBadge;