import Icon from '@/components/ui/Icon';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
export const Card = ({
  title,
  text,
  overlay,
  overlayColor,
  overlayTitle,
  background,
  bgClasses,
  className,
  image,
  icon,
  titleTag = 'h3',
  href,
  footer,
  external,
  imageClasses,
  contentClasses,
  children,
  featured,
  ...props
}) => {
  const classes = cn({
    card: true,
    [className]: className
  });
  const imageCn = cn({
    'card-image': true,
    [imageClasses]: imageClasses
  });
  const cardContentCn = cn({
    'card-content': true,
    [contentClasses]: contentClasses
  });
  const bgCn = cn({
    background: true,
    'absolute inset-0': true,
    'w-full h-full': true,
    [bgClasses]: bgClasses
  });
  const Heading = titleTag;
  const CardWrapper = ({
    children,
    ...props
  }) => {
    if (href && !external) {
      return <Link className={classes} href={href} {...props}>
          {children}
        </Link>;
    } else if (href && external) {
      return <a className={classes} href={href} target="_blank" rel="noreferrer" {...props}>
          {children}
        </a>;
    }
    return <div className={classes} {...props} data-sentry-component="CardWrapper" data-sentry-source-file="Card.jsx">
        {children}
      </div>;
  };
  return <CardWrapper className={classes} {...props} data-sentry-element="CardWrapper" data-sentry-component="Card" data-sentry-source-file="Card.jsx">
      {(image || background) && <div className={imageCn}>
          {image && <img src={image} alt={title} />}

          {overlay && <div className="overlay">{overlay}</div>}

          {overlayColor && <div className={`overlay overlay-${overlayColor}`}>{overlay}</div>}

          {overlayTitle && <Heading className="overlay-title">{overlayTitle}</Heading>}

          {background && <div className={bgCn}>{background}</div>}
        </div>}

      <div className={cardContentCn}>
        {icon && <div className="card-icon">
            <Icon name={icon} />
          </div>}
        {title && <Heading>{title}</Heading>}
        {text && <p>{text}</p>}
        {children}
      </div>

      {footer && <div className="card-footer">{footer}</div>}
    </CardWrapper>;
};
export default Card;