'use client';

import { isMobile } from 'react-device-detect';
import { CommandItem } from '@/components/ui/Command/Command';
import Icon from '@/components/ui/Icon';
import { useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
export const NavCommandItem = ({
  item,
  setOpen
}) => {
  const router = useRouter();
  const t = useTranslations();
  const handleSelect = path => {
    setOpen(false);
    router.push(path);
  };
  return <CommandItem id={item.id} value={item.title} keywords={[item.id, item.title, item.path]} onSelect={() => handleSelect(item.path)} className="gap-3" data-sentry-element="CommandItem" data-sentry-component="NavCommandItem" data-sentry-source-file="NavCommandItem.jsx">
      <Icon name={item.icon} type={item?.iconType} className="inline-flex items-center justify-center w-4 h-4 shrink-0" data-sentry-element="Icon" data-sentry-source-file="NavCommandItem.jsx" />

      <span className="flex-1 leading-4">
        {t(item.title)}
      </span>

      {item.shortcut && !isMobile && <div className="flex items-center gap-2">
          <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono font-medium text-muted-foreground ml-auto">
            {item.shortcut}
          </kbd>
        </div>}
    </CommandItem>;
};
export default NavCommandItem;