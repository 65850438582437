'use client';

import { usePathname } from 'next/navigation';
import { useState } from 'react';
import Button from '@/components/ui/Button';
import { ROUTES } from '@/data';
import { useSession } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { useAppStore } from '@/store';
import { cn } from '@/utils';
export const AuthButton = ({
  className,
  variant = 'outline',
  icon,
  label = 'Login'
}) => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const {
    setRedirect
  } = useAppStore();
  const {
    data: session
  } = useSession();
  if (session) {
    return null;
  }
  const handleSignIn = () => {
    setLoading(true);
    setRedirect(pathname);
    router.push(ROUTES.LOGIN);
  };
  const classes = cn({
    [className]: className
  });
  return <Button variant={variant} className={classes} onClick={handleSignIn} icon={icon} label={label} loading={loading} data-sentry-element="Button" data-sentry-component="AuthButton" data-sentry-source-file="AuthButton.jsx" />;
};
export default AuthButton;