import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
const CuratorCard = ({
  curator,
  children,
  titleTag = 'h3',
  className,
  featured = false,
  star = false,
  ...props
}) => {
  const t = useTranslations();
  const classes = cn({
    card: true,
    'curator-card': true,
    'curator-card-featured': featured,
    'curator-card-star': star,
    [className]: className
  });
  const Heading = titleTag;
  return <Link href={`${ROUTES.CURATORS}/${curator.id}`} className={classes} {...props} data-sentry-element="Link" data-sentry-component="CuratorCard" data-sentry-source-file="CuratorCard.jsx">
      <div className="card-content">
        <Avatar star={star} src={curator.images} size="lg" className="rounded-full" alt={curator?.display_name ? curator.display_name : curator?.id} data-sentry-element="Avatar" data-sentry-source-file="CuratorCard.jsx" />

        <div className="flex flex-col items-center justify-center">
          <Heading className="inline-flex items-center gap-2" data-sentry-element="Heading" data-sentry-source-file="CuratorCard.jsx">
            {curator?.display_name ? curator.display_name : curator?.id}
          </Heading>

          <Badge icon="UsersRound" label={t('common.metrics.followers')} value={curator?.followers?.total || `0`} variant={star ? 'gold' : 'light'} data-sentry-element="Badge" data-sentry-source-file="CuratorCard.jsx" />
        </div>

        {children}
      </div>
    </Link>;
};
export default CuratorCard;