import { ProgressBar } from '@/components/ui/Progress';
import { useTranslations } from '@/hooks';
export const MatchAudioFeatures = ({
  values
}) => {
  const t = useTranslations();
  if (!values) return null;
  return <div className="playlist-match" data-sentry-component="MatchAudioFeatures" data-sentry-source-file="MatchAudioFeatures.jsx">
      <ProgressBar compact label={t('match.spotify.features.danceability.label')} labelClasses="font-medium tracking-tight" icon="Guitar" valueLabel={`${values?.danceability}%`} value={values?.danceability} variant="red" tooltipText={t('match.spotify.features.danceability.tooltip')} tooltipProps={{
      side: 'bottom',
      className: `max-w-[200px]`
    }} data-sentry-element="ProgressBar" data-sentry-source-file="MatchAudioFeatures.jsx" />

      <ProgressBar compact label={t('match.spotify.features.acousticness.label')} labelClasses="font-medium tracking-tight" icon="Disc3" valueLabel={`${values?.acousticness}%`} value={values?.acousticness} variant="orange" tooltipText={t('match.spotify.features.acousticness.tooltip')} tooltipProps={{
      side: 'bottom',
      className: `max-w-[200px]`
    }} data-sentry-element="ProgressBar" data-sentry-source-file="MatchAudioFeatures.jsx" />

      <ProgressBar compact label={t('match.spotify.features.energy.label')} labelClasses="font-medium tracking-tight" icon="Zap" valueLabel={`${values?.energy}%`} value={values?.energy} variant="yellow" tooltipText={t('match.spotify.features.energy.tooltip')} tooltipProps={{
      side: 'bottom',
      className: `max-w-[200px]`
    }} data-sentry-element="ProgressBar" data-sentry-source-file="MatchAudioFeatures.jsx" />

      <ProgressBar compact label={t('match.spotify.features.instrumentalness.label')} labelClasses="font-medium tracking-tight" icon="Guitar" valueLabel={`${values?.instrumentalness}%`} value={values?.instrumentalness} variant="green" tooltipText={t('match.spotify.features.instrumentalness.tooltip')} tooltipProps={{
      side: 'top',
      className: `max-w-[200px]`
    }} data-sentry-element="ProgressBar" data-sentry-source-file="MatchAudioFeatures.jsx" />

      <ProgressBar compact label={t('match.spotify.features.speechiness.label')} labelClasses="font-medium tracking-tight" icon="MicVocal" valueLabel={`${values?.speechiness}%`} value={values?.speechiness} variant="teal" tooltipText={t('match.spotify.features.speechiness.tooltip')} tooltipProps={{
      side: 'top',
      className: `max-w-[200px]`
    }} data-sentry-element="ProgressBar" data-sentry-source-file="MatchAudioFeatures.jsx" />

      <ProgressBar compact label={t('match.spotify.features.mood.label')} labelClasses="font-medium tracking-tight" icon="Smile" valueLabel={`${values?.valence}%`} value={values?.valence} variant="blue" tooltipText={t('match.spotify.features.mood.tooltip')} tooltipProps={{
      side: 'top',
      className: `max-w-[200px]`
    }} data-sentry-element="ProgressBar" data-sentry-source-file="MatchAudioFeatures.jsx" />

      <ProgressBar compact label={t('match.spotify.features.tempo.label')} labelClasses="font-medium tracking-tight" icon="Hourglass" valueLabel={`${values?.tempo} %`} value={values?.tempo} variant="purple" tooltipText={t('match.spotify.features.tempo.tooltip')} tooltipProps={{
      side: 'top',
      className: `max-w-[200px]`
    }} data-sentry-element="ProgressBar" data-sentry-source-file="MatchAudioFeatures.jsx" />
    </div>;
};
export default MatchAudioFeatures;