import { cn } from '@/utils';
export const Emoji = props => {
  const {
    className,
    label,
    symbol
  } = props;
  const classes = cn({
    'emoji': true,
    [className]: className
  });
  return <span className={classes} role="img" aria-label={label} aria-hidden={label ? 'false' : 'true'} data-sentry-component="Emoji" data-sentry-source-file="index.jsx">
      {symbol}
    </span>;
};
export default Emoji;