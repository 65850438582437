'use client';

import { SEARCH_TABS } from '@/data';
import { useTranslations } from '@/hooks';
export const SearchTabs = ({
  activeTab,
  setActiveTab
}) => {
  const t = useTranslations();
  return <div className="border-b" data-sentry-component="SearchTabs" data-sentry-source-file="SearchTabs.jsx">
      <nav className="flex gap-2" aria-label="Tabs">
        {SEARCH_TABS.map(tab => <button key={tab.id} onClick={() => setActiveTab(tab.id)} className={`px-3 py-2 text-sm border-b-2 ${activeTab === tab.id ? 'border-primary text-foreground' : 'border-transparent text-muted-foreground hover:text-foreground'}`}>
            {t(tab.label)}
          </button>)}
      </nav>
    </div>;
};
export default SearchTabs;