import { Placeholder } from '@/components/ui/Placeholder';
import { cn } from '@/utils';
export function PlaylistCover({
  image,
  name,
  className,
  ...props
}) {
  const classes = cn({
    'playlist-cover': true,
    'relative cursor-pointer': true,
    [className]: className
  });
  if (image) {
    return <div {...props} className={classes}>
        <img src={image} alt={name} className="absolute object-cover w-full h-full" />
      </div>;
  } else {
    return <Placeholder className={classes} icon="Music" {...props} />;
  }
}
export default PlaylistCover;