import { useContext } from 'react';
import Icon from '@/components/ui/Icon';
import { Placeholder } from '@/components/ui/Placeholder';
import { ThemeContext } from '@/context/ThemeProvider';
import { cn } from '@/utils';
export const TrackPlayer = ({
  cover,
  audioSrc,
  disablePlayer,
  className
}) => {
  const disable = disablePlayer || audioSrc === null;
  const {
    audio,
    setAudio,
    isPlaying,
    setIsPlaying,
    pause,
    duration,
    currentTime
  } = useContext(ThemeContext);
  const handlePlay = () => {
    if (audio === audioSrc) {
      setIsPlaying(!isPlaying);
    } else if (audio !== audioSrc) {
      setAudio(audioSrc);
    } else {
      pause();
    }
  };
  const classes = cn({
    'track-player': true,
    'is-playing': isPlaying && audio === audioSrc,
    [className]: className
  });
  return <button onClick={handlePlay} className={classes} data-sentry-component="TrackPlayer" data-sentry-source-file="TrackPlayer.jsx">
      {cover ? <div className="track-thumbnail" style={{
      backgroundImage: `url("${cover}")`
    }} /> : <Placeholder icon="Music" />}

      {!disable && <div className="overlay">
          {isPlaying && audio === audioSrc ? <Icon name="Pause" /> : <Icon name="Play" />}
          {audio === audioSrc && <div className="track-progress">
              <div style={{
          width: `${currentTime / duration * 100}%`
        }} className="track-progress" />
            </div>}
        </div>}
    </button>;
};
export default TrackPlayer;