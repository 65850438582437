'use client';

import { useInfiniteQuery } from '@tanstack/react-query';
import { AnimatePresence, motion, MotionConfig } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { matchfyApi } from '@/api';
import { Chat, ChatActive, ChatArchive, ChatArchiveToggle } from '@/components/Chat';
import Spinner from '@/components/ui/Spinner';
import Wrapper from '@/components/ui/Wrapper';
import { ROUTES, QUERIES, generateRoute } from '@/data';
import { useRouter } from '@/i18n/routing';
import { useChatStore } from '@/store';
import { cn } from '@/utils';
export const ChatList = ({
  detail,
  id,
  mobile,
  user,
  className
}) => {
  const {
    activeSid,
    setActiveChat,
    chatList,
    archiveList,
    setArchiveList,
    setActiveSid,
    setChatList,
    chatTotal,
    setChatTotal
  } = useChatStore();
  const [showArchive, setShowArchive] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const router = useRouter();
  const {
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [QUERIES.CHAT.LIST, user.spotifyId],
    queryFn: async params => {
      const res = await matchfyApi.listChats(params, user.spotifyId);
      if (res?.data?.total !== chatTotal) {
        setChatList(res?.data?.chats);
        setArchiveList(res?.data?.archive);
        setChatTotal(archiveList.length + chatList.length);
        if (chatTotal >= archiveList.length + chatList.length) {
          setEnabled(false);
        }
        return res;
      }
    },
    getNextPageParam: lastPage => {
      if (!lastPage?.nextPage) return undefined;
      return lastPage.nextPage;
    },
    staleTime: 300000,
    cacheTime: 900000,
    enabled: enabled && !!user
  });
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage]);
  useEffect(() => {
    if (chatList.length > 0 && id && activeSid && activeSid !== id || id && !activeSid) {
      const chat = chatList.find(chat => chat.sid === id);
      if (chat) {
        setActiveSid(id);
        setActiveChat(chat);
      }
    }
  }, [id, chatList]);
  const handleChatSelect = chat => {
    router.push(generateRoute(ROUTES.CHAT_DETAIL, {
      id: chat.sid
    }));
    setActiveChat(chat);
    setActiveSid(chat.sid);
  };
  const classes = cn({
    'chat-wrapper': true,
    'chat-detail': detail,
    'chat-list': !detail,
    'chat-mobile': mobile,
    [className]: className
  });
  const variants = {
    initial: {
      opacity: 0,
      x: '-100%'
    },
    animate: {
      opacity: 1,
      x: 0
    },
    exit: {
      opacity: 0,
      x: '-100%'
    }
  };
  return <MotionConfig transition={{
    duration: 0.5,
    type: 'spring',
    bounce: 0
  }} data-sentry-element="MotionConfig" data-sentry-component="ChatList" data-sentry-source-file="ChatList.jsx">
      <Wrapper data-sentry-element="Wrapper" data-sentry-source-file="ChatList.jsx">
        {isLoading && <Spinner />}

        {!isLoading && <div className={classes}>
            <div className="chat-sidebar">
              <ChatArchiveToggle showArchive={showArchive} setShowArchive={setShowArchive} total={archiveList.length} />

              <AnimatePresence mode="popLayout">
                {showArchive ? <motion.div key="chat-archive" className="w-full h-full" variants={variants} initial="initial" animate="animate" exit="exit">
                    <ChatArchive archiveList={archiveList} activeSid={activeSid} showArchive={showArchive} setShowArchive={setShowArchive} handleChatSelect={handleChatSelect} chatList={chatList} isLoading={isLoading} />
                  </motion.div> : <motion.div key="chat-list" className="w-full h-full" variants={variants} initial="initial" animate="animate" exit="exit">
                    <ChatActive activeSid={activeSid} showArchive={showArchive} handleChatSelect={handleChatSelect} chatList={chatList} isLoading={isLoading} />
                  </motion.div>}
              </AnimatePresence>

              {!isLoading && <div ref={ref} className="w-full h-1" />}

              {isLoading && <Spinner inline />}
            </div>

            <Chat activeSid={activeSid} detail={detail} user={user} mobile={mobile} />
          </div>}
      </Wrapper>
    </MotionConfig>;
};
export default ChatList;