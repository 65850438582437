import Avatar from '@/components/ui/Avatar';
import Icon from '@/components/ui/Icon';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
const CuratorBanner = ({
  back,
  curator,
  curatorId,
  curatorName,
  children,
  titleTag = 'h3',
  className,
  featured,
  ...props
}) => {
  const t = useTranslations();
  const classes = cn({
    card: true,
    'curator-banner': true,
    'curator-banner-featured': featured,
    [className]: className
  });
  const Heading = titleTag;
  return <Link href={`${ROUTES.CURATORS}/${curatorId}`} className={classes} {...props} data-sentry-element="Link" data-sentry-component="CuratorBanner" data-sentry-source-file="CuratorBanner.jsx">
      <div className="flex items-center gap-3">
        {back && <Icon name="ArrowLeft" className="text-xl" />}

        <Avatar src={curator.avatar} alt={curatorName} data-sentry-element="Avatar" data-sentry-source-file="CuratorBanner.jsx" />
      </div>

      <div>
        <Heading className="m-0" data-sentry-element="Heading" data-sentry-source-file="CuratorBanner.jsx">
          {curatorName}
        </Heading>

        {children}
      </div>
    </Link>;
};
export default CuratorBanner;